import React from 'react';
import {
  FaCalendarAlt,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { format } from 'date-fns';
import { bn } from 'date-fns/locale';

const TopAppBar = () => {
  const bengaliNumerals = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];

  // Today date and day -----------------------------
  const today = new Date();
  const todayBanglaDate = format(today, 'EEEE, do MMMM yyyy', {
    locale: bn,
  }).replace(/\d/g, (digit) => bengaliNumerals[digit]);

  return (
    <div className="bg-gray py-2">
      <div className="w-11/12 lg:w-4/5 mx-auto flex justify-between items-center">
        <div className="flex items-center gap-2">
          <FaCalendarAlt className=" text-[#908C8C]" />
          <p className="text-base-100 text-sm">{todayBanglaDate}</p>
        </div>

        <div className="flex gap-2 items-center">
          <a
            href="https://www.facebook.com/NagorikTV/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            <FaFacebookF className="bg-red text-white text-2xl p-1 rounded-sm" />
          </a>
          <a
            href="https://twitter.com/NagorikTV"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="bg-red text-white text-2xl p-1 rounded-sm" />
          </a>
          <a
            href="https://youtube.com/c/NagorikTV"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube className="bg-red text-white text-2xl p-1 rounded-sm" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopAppBar;
