import React from 'react';
import './LivePage.css';

const LivePage = () => {
    // src="https://www.youtube.com/embed/gTY0kJBkmNw?autoplay=1"
    // src="https://www.youtube.com/embed/live_stream?channel=UCu5_9xekpXOIlyqRHWqOd_Q"
    // test url-----------------
    // src ='https://www.youtube.com/embed/live_stream?channel=UCk8ZtplhKrTtLXBAzQYMCzQ&autoplay=1';
    return (
        <div className="w-11/12 lg:w-2/3 mx-auto lg:py-5">
            {/* <h2 className='text-xl lg:text-3xl font-bold underline text-center lg:mt-5'>Live Video</h1> */}
            <div className="iframe-container mb-10 mt-0 md:mt-5 ">
                <iframe
                    src="https://www.youtube.com/embed/live_stream?channel=UCu5_9xekpXOIlyqRHWqOd_Q&autoplay=1"
                    title="Nagorik TV"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};

export default LivePage;
