import React from 'react';
import img from '../../Assets/images/ads.png';

const DiscountAdd = () => {
  return (
    <div className="mt-10 md:mt-20 lg:mt-0">
      <img src={img} alt="" />
    </div>
  );
};

export default DiscountAdd;
