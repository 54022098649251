import React from 'react';
import ads from '../../Assets/images/image.jpg'

const Ads = () => {
    return (
        <div className='mt-5 my-5'>
            <img src={ads} alt="" />
        </div>
    );
};

export default Ads;