import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaHome, FaTags } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import PostComment from '../../Components/SingleNewsPage/postComments';
import PostCarousel from '../../Components/postCarousel';
import Sidebar from '../../Components/sideBar';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { format } from 'date-fns';
import ShareButtons from '../../Shared/ShareButtons/ShareButtons';

const limitWords = (text, limit) => {
  if (typeof text !== 'string') {
    return '';
  }

  let words = text.split(' ');

  if (words.length > limit) {
    words = words.slice(0, limit);
    return `${words.join(' ')}...`;
  }

  return text;
};

const SingleNewsPage = () => {
  const id = useParams();
  const [news, setNews] = useState();
  const [categoryNews, setCategoryNews] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [category, setCategory] = useState([]);

  // get single news details----------------------
  useEffect(() => {
    axios(`https://app.zufaa.tech/nagorik-tv/articleapi/${id?.id}`)
      .then((res) => {
        const news = res?.data;
        setNews(res?.data);
        window.scrollTo(0, 0);

        axios('https://app.zufaa.tech/nagorik-tv/categories/')
          .then((res) => {
            const category = res?.data?.find(
              (category) => category?.name === news?.categories[0]
            );
            setCategory(category);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const categoryId = Math.floor(Math.random() * (12 - 1 + 1)) + 1;
  // Get all Category Article----------------------
  useEffect(() => {
    axios(
      `https://app.zufaa.tech/nagorik-tv/category-article/${categoryId}/1/10`
    )
      .then((res) => {
        // console.log( res.data );
        setCategoryNews(res.data);
        // console.log( categoryId );
      })
      .catch((error) => {
        // console.log( error );
      });
  }, []);

  let getLatestNews = [];
  // Get Latest news-----------------------------
  useEffect(() => {
    axios('https://app.zufaa.tech/nagorik-tv/latest-news/')
      .then((res) => {
        const latestNews = res?.data?.body;

        if (latestNews?.length) {
          for (const news of latestNews) {
            axios(
              `https://app.zufaa.tech/nagorik-tv/articleapi/${news?.article_id}`
            )
              .then((res) => {
                // console.log( res.data );

                getLatestNews.push(res?.data);
                const uniqueArray2 = getLatestNews.filter(
                  (obj, index, self) => {
                    return (
                      index ===
                      self.findIndex((o) => o.article_id === obj.article_id)
                    );
                  }
                );
                setLatestNews(uniqueArray2);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // const copyLink = () => {
  //   const postLink = window.location.href;
  //   navigator.clipboard.writeText(postLink);
  //   alert('Link copied to clipboard!');
  // };

  const shareUrl = window.location.href;
  const shareUrlWithData = `${news?.title} \n ${news?.images[0]?.file_path
    } \n ${news?.content?.slice(0, 80) + '....'}\n ${shareUrl}`;
  // console.log(shareUrlWithData);

  // const newsImages = (news?.images?.slice(1));

  // console.log(format(new Date(news?.publication_date), 'MMM-dd-yyyy'));
  return (
    <div className="w-11/12 lg:w-4/5 mx-auto">
      <Helmet>
        <title>{news?.title}</title>
        <meta name="description" content={limitWords(news?.content, 10)} />
        <meta property="og:title" content={news?.title} />
        <meta
          property="og:description"
          content={limitWords(news?.content, 10)}
        />
        <meta property="og:image" content={news?.images[0]?.file_path} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <nav className="flex md:mt-5 lg:mt-14" aria-label="breadcrumb">
        <ol className="flex items-center space-x-2 text-[#B6B5B5] text-sm">
          <li>
            <FaHome />
          </li>
          <li>
            <Link to="/" className="text-blue-500 hover:underline">
              হোম
            </Link>
          </li>
          <li className="text-gray-900">/</li>
          <li className="text-gray-900">{news?.categories[0]}</li>
        </ol>
      </nav>

      <div className="news-title-share-meta mt-5 md:w-9/12">
        <div className="title">
          <h2 className="text-3xl md:text-5xl text-black">{news?.title} </h2>
          <hr className="border-[#D9D9D9] border-2 mt-5"></hr>
        </div>

        <div className="post-meta-share mt-5 md:flex  justify-between">
          <div className="post-meta">
            <ol className="flex items-center flex-wrap space-x-3 text-[#B6B5B5] text-sm">
              <li>
                <span>By </span>
                <Link to="/" className="text-sky hover:underline">
                  Nagorik Tv
                </Link>
              </li>
              <li className="text-gray-900">
                <span>In </span>
                <Link
                  to={`/newscategory/${category?.name}/${category?.category_id}`}
                  className="text-sky hover:underline"
                >
                  {news?.categories[0]}
                </Link>
              </li>
              <li className="text-gray-900 flex gap-2">
                <span>Posted </span>
                {news?.publication_date && (
                  <div className="text-sky ">
                    {format(new Date(news?.publication_date), 'MMM-dd-yyyy')}
                  </div>
                )}
              </li>
            </ol>
          </div>
          <div className="post-meta-social-share mt-5 md:mt-0 md:flex justify-between">
            {/* <SocialShare
              copyLink={copyLink}
              title={news?.title}
              imageUrl={news?.images[0]?.file_path}
              content={limitWords(news?.content, 10)}
            /> */}
            <ShareButtons
              shareUrl={shareUrl}
              shareUrlWithData={shareUrlWithData}
            />
          </div>
        </div>
      </div>

      <div className="newscontent-sidebar mt-5 md:flex gap-5">
        <div className="news-contents md:w-9/12">
          <img
            src={news?.images[0]?.file_path}
            alt=""
            className="rounded-sm w-full object-cover"
          />

          <div className="my-5 lg:my-14 text-news-p text-base lg:text-xl ">
            {news?.content.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
                <img
                  src={news?.images[index + 1]?.file_path}
                  alt=""
                  className="rounded-sm w-full object-cover"
                />
              </React.Fragment>
            ))}
          </div>
          <hr className="border-[#D9D9D9] border-2 mt-5"></hr>

          <div className="post-meta-share">
            <div className="post-meta flex items-center gap-2 mt-5 text-[#908C8C]">
              <FaTags className="md:mr-2" />
              {news?.tags.map((tag, index) => (
                <div
                  key={index}
                  className="border py-1 px-5 hover:text-sky hover:ring-1 hover:ring-offset-1 hover:ring-sky transition-all duration-300"
                >
                  #{tag}
                </div>
              ))}
            </div>

            <div className="share-social mt-5">
              {/* <SocialShare
                copyLink={copyLink}
                title={news?.title}
                imageUrl={news?.images[0]?.file_path}
                content={limitWords(news?.content, 10)}
              /> */}
              <ShareButtons
                shareUrl={shareUrl}
                shareUrlWithData={shareUrlWithData}
              />
            </div>
          </div>
          <hr className="border-[#D9D9D9] border-2 mt-5 md:mt-12"></hr>

          <div className="post-comments mt-5">
            <PostComment id={news?.article_id} />
          </div>
          <hr className="border-[#D9D9D9] border-2 mt-5 md:mt-12"></hr>

          <div className="post-carousel mt-3 md:mb-20">
            <PostCarousel categoryNews={categoryNews} />
          </div>
        </div>

        <div className="sidebar md:w-3/12 mt-8 lg:mt-0">
          <Sidebar latestNews={latestNews} />
          {/* <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <VotingComponent
              question="Do you like React?"
              imageURL={ postImage }
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SingleNewsPage;
