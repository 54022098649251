import React, { useState, useEffect, useRef } from 'react';
// import SocialShare from '../../Components/ShareForPoll/PollShare';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';
import 'swiper/swiper-bundle.css';
import SinglePoll from './SinglePoll/SinglePoll';

const VotingComponent = ({ copyLink }) => {
  const [polls, setPolls] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [refresh, setRefresh] = useState(false);
  const swiperRef = useRef(null);
  const [ipAddress, setIPAddress] = useState();

  // Get IP address of a PC-------------------------------
  const getIPAddress = localStorage.getItem('IPAddress');

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        const ipAddress = data.ip;
        // localStorage.setItem('IPAddress', ipAddress)
        setIPAddress(ipAddress);
        // Perform further actions with the IP address
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  // get single vote--------------
  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // get Final vote--------------------------
  const handleVote = ([e, poll]) => {
    e.preventDefault();

    const findKeyByValue = (poll, value) => {
      for (let [key, val] of Object.entries(poll)) {
        if (val === value) {
          return { key, val };
        }
      }
      return;
    };
    const desiredValue = selectedOption;
    const foundPair = findKeyByValue(poll, desiredValue);
    // vote poll calculation---------------------------
    const newPoll = {
      id: poll?.id,
      question: poll?.question,
      option_1: poll?.option_1,
      option_2: poll?.option_2,
      option_3: poll?.option_3,
      option_1_vote: poll?.option_1_vote,
      option_2_vote: poll?.option_2_vote,
      option_3_vote: poll?.option_3_vote,
      publication_date: poll?.publication_date,
      image: poll?.image,
    };
    // console.log( newPoll );
    if (foundPair?.key === 'option_1') {
      newPoll.option_1_vote = poll?.option_1_vote + 1;
      // console.log( newPoll );
      pollCount(newPoll);

      return;
    } else if (foundPair?.key === 'option_2') {
      newPoll.option_2_vote = poll?.option_2_vote + 1;
      // console.log( newPoll );
      pollCount(newPoll);

      return;
    } else if (foundPair?.key === 'option_3') {
      newPoll.option_3_vote = poll?.option_3_vote + 1;
      // console.log( newPoll );
      pollCount(newPoll);

      return;
    }
  };

  const pollCount = (poll) => {
    const url = `https://app.zufaa.tech/nagorik-tv/polls/${poll?.id}`;
    axios
      .put(url, poll)
      .then((res) => {
        setRefresh(!refresh);
        localStorage.setItem('IPAddress', ipAddress);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get All Polls----------------------------
  useEffect(() => {
    fetch('https://app.zufaa.tech/nagorik-tv/polls/')
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setPolls(data);
      });
  }, [refresh]);

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  // /voting/:id
  // console.log(useLocation());
  // let fullPollUrl = `${window.location.origin} /voting/${}`;
  // console.log(fullPollUrl);

  return (
    <div className="mt-10">
      <hr className="border-b border-red" />
      <div className="flex justify-between">
        <h2 className="text-red text-xl font-bold py-2">
          অনলাইন ভোট (চেকবক্স)
        </h2>
        <div className="flex gap-1 items-center text-[#B8B8B8]">
          <FaLongArrowAltLeft
            onClick={handlePrevClick}
            className="font-bold text-xl hover:text-sky"
          />
          /
          <FaLongArrowAltRight
            onClick={handleNextClick}
            className="font-bold text-xl hover:text-sky"
          />
        </div>
      </div>
      <div>
        <Swiper ref={swiperRef} slidesPerView={1} spaceBetween={30}>
          {polls?.map((poll, i) => (
            <SwiperSlide key={i}>
              {
                <SinglePoll
                  poll={poll}
                  key={i}
                  selectedOption={selectedOption}
                  handleRadioChange={handleRadioChange}
                  getIPAddress={getIPAddress}
                  ipAddress={ipAddress}
                  handleVote={handleVote}
                ></SinglePoll>
              }
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
export default VotingComponent;
