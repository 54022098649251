import React from 'react';
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';

const NagorikViral = () => {
  const [topvideo, setTopVideo] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoPage, setVideoPage] = useState(1);

  const handleVideo = (video) => {
    setTopVideo(video);
  };

  // Handle Viral news-------------------
  const handlerViralNewsLeft = () => {
    if (videoPage === 1) {
      setVideoPage(1);
      return;
    }

    setVideoPage(videoPage - 1);
  };

  const handleViralNewsRight = () => {
    setVideoPage(videoPage + 1);

    if (videos?.length < 4) {
      setVideoPage(videoPage);
    }
  };

  // Get Viral Video---------------------
  useEffect(() => {
    axios(
      `https://app.zufaa.tech/nagorik-tv/category-video/8/${videoPage}/4`
    ).then((res) => {
      setTopVideo(res?.data[0]);
      setVideos(res?.data);
    });
  }, [videoPage]);

  return (
    <div className="mt-10 md:mt-20">
      <hr className="border-b border-red" />
      <div className="flex justify-between">
        <h2 className="text-red text-lg font-bold py-3">নাগরিক ভাইরাল</h2>
        <div className="flex gap-1 items-center text-[#B8B8B8]">
          <FaLongArrowAltLeft
            className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
            onClick={handlerViralNewsLeft}
          />
          /
          <FaLongArrowAltRight
            className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
            onClick={handleViralNewsRight}
          />
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-20 md:gap-16 lg:gap-5">
        <div>
          {/* <h2 className='bg-gray px-2 py-1 text-white w-24'>রাজনীতি</h2> */}

          <div className="h-[200px] md:h-[420px] lg:h-[200px] xl:h-[250px] xxl:h-[250px] xxxl:h-[350px]">
            <iframe
              src={topvideo?.video_url}
              // width="1120" height="675"
              title="Nagorik TV"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="w-full h-full"
            ></iframe>
            <p className="text-base md:text-lg font-semibold p-3">
              {topvideo?.video_title}
            </p>
          </div>
        </div>

        <div>
          <div className="grid grid-cols-2 gap-5">
            {videos?.map((video, i) => (
              <div
                onClick={() => handleVideo(video)}
                key={i}
                className="cursor-pointer shadow w-full"
              >
                <div>
                  {video?.video_url?.split('/').pop() && (
                    <img
                      src={`https://img.youtube.com/vi/${video?.video_url
                        ?.split('/')
                        .pop()}/maxresdefault.jpg`}
                      alt=""
                      className="w-full h-full object-cover "
                    />
                    // <YoutubeThumbnail videoUrl={video?.video_url} />
                  )}
                </div>

                <p className="col-span-2 p-2 text-sm md:text-base lg:text-base text-justify">
                  {video?.video_title?.length > 43
                    ? video?.video_title?.slice(0, 43) + '...'
                    : video?.video_title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NagorikViral;
