import React from 'react';
import {
  FaCalendarAlt,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
} from 'react-icons/fa';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const NagorikBinodo = () => {
  const [nagorikBinodon, setNagorikBinodon] = useState([]);
  const [nagorikPage, setNagorikPage] = useState(1);

  // Handle Editors news-------------------
  const handleNagorikBinodonNewsLeft = () => {
    if (nagorikPage === 1) {
      setNagorikPage(1);
      return;
    }

    setNagorikPage(nagorikPage - 1);
  };

  const handleNagorikBinodonNewsRight = () => {
    setNagorikPage(nagorikPage + 1);

    // console.log(sportsNews?.length);
    if (nagorikBinodon?.length < 4) {
      setNagorikPage(nagorikPage);
    }
  };

  // Get all Nagorikbinodon News-------------------------
  useEffect(() => {
    axios(
      `https://app.zufaa.tech/nagorik-tv/category-article/11/${nagorikPage}/5`
    )
      .then((res) => {
        // console.log(res.data);
        setNagorikBinodon(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [nagorikPage]);

  return (
    <div className="mt-14">
      <hr className="border-b border-red" />
      <div className="flex justify-between">
        <h2 className="text-red text-xl font-bold py-3">নাগরিক বিনোদন</h2>
        <div className="flex gap-1 items-center text-[#B8B8B8]">
          <FaLongArrowAltLeft
            onClick={handleNagorikBinodonNewsLeft}
            className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
          />
          /
          <FaLongArrowAltRight
            onClick={handleNagorikBinodonNewsRight}
            className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
          />
        </div>
      </div>

      <div className="lg:grid grid-cols-3 gap-5">
        {/* Top Nagorik Binodon---------------------- */}
        <div className="lg:col-span-2">
          <Link
            to={`/singlenews/${nagorikBinodon[0]?.article_id}`}
            className="relative cursor-pointer duration-500 hover:gap-5 rounded-md card hover:z-20 col-span-2 row-span-4"
          >
            <img
              src={nagorikBinodon[0]?.images[0].file_path}
              alt=""
              className="rounded-sm object-cover "
            />

            <div className="newsoverlay">
              <div className="text-white p-5 absolute bottom-0">
                <div className="flex gap-2 items-center">
                  <FaCalendarAlt className="text-sm" />
                  {nagorikBinodon[0]?.publication_date && (
                    <p className="text-sm ">
                      {format(
                        new Date(nagorikBinodon[0]?.publication_date),
                        'MMM-dd-yyyy'
                      )}
                    </p>
                  )}
                </div>
                <h2 className="">{nagorikBinodon[0]?.title}</h2>
              </div>
            </div>
          </Link>
        </div>

        <hr className="my-4 outline-1 block lg:hidden" />

        {/* All Nagorik Binodon News------------- */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-5 lg:gap-0 lg:grid-cols-1">
          {nagorikBinodon?.map((news, i) => (
            <Link to={`/singlenews/${news?.article_id}`} key={i} className="">
              <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-0 lg:gap-5 items-center md:items-start lg:items-center rounded-sm">
                <img
                  src={news?.images[0]?.file_path}
                  alt=""
                  className="rounded-sm w-full md:col-span-2 lg:col-span-1 object-cover"
                />

                <div className="md:mt-3 lg:mt-0 p-0 md:p-2 lg:p-0 col-span-2">
                  <div className="flex gap-2 mb-1 text-[#908C8C]">
                    <FaCalendarAlt className="" />
                    {news?.publication_date && (
                      <p className="text-xs md:text-base lg:text-sm">
                        {format(
                          new Date(news?.publication_date),
                          'MMM-dd-yyyy'
                        )}
                      </p>
                    )}
                  </div>

                  <h2>
                    {news?.title.length > 60
                      ? news?.title?.slice(0, 60) + '...'
                      : news?.title}
                  </h2>
                </div>
              </div>
              {i + 1 === nagorikBinodon?.length ? (
                <></>
              ) : (
                <hr className="col-span-3 my-3 md:hidden bolck border-[#E2E2E2] lg:block" />
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NagorikBinodo;
