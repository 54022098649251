import SingleNewsPage from '../../Components/SingleNewsPage/SingleNewsPage';
import VotingComponent from '../../Components/VotingSinglePage/VotingSinglePage';
import AllVideos from '../../Pages/AllVideos/AllVideos';
import Category from '../../Pages/Category/Category';
import JustCategory from '../../Pages/JustCategory/JustCategory';
import LivePage from '../../Pages/LivePage/LivePage';
import SearchNews from '../../Shared/SearchNews/SearchNews';

const { createBrowserRouter } = require('react-router-dom');
const { default: Main } = require('../../Layouts/Main/Main');
const { default: Home } = require('../../Pages/Home/Home/Home');
const {
  default: PageNotFound,
} = require('../../Shared/PageNotFound/PageNotFound');

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Main></Main>,
    children: [
      {
        path: '/',
        element: <Home></Home>,
      },
      {
        path: '/singlenews/:id',
        element: <SingleNewsPage></SingleNewsPage>,
      },
      {
        path: '/category/:cId/:subId',
        element: <Category></Category>,
      },
      {
        path: '/newscategory/:name/:cId',
        element: <JustCategory></JustCategory>,
      },
      {
        path: '/liveVideo',
        element: <LivePage></LivePage>,
      },
      {
        path: '/allvideos',
        element: <AllVideos></AllVideos>,
      },
      {
        path: '/voting/:id',
        element: <VotingComponent></VotingComponent>,
      },
      {
        path: '/search/:search',
        element: <SearchNews></SearchNews>,
      },
    ],
  },
  {
    path: '*',
    element: <PageNotFound></PageNotFound>,
  },
]);
