import React, { useEffect, useState } from 'react';
import {
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
} from 'react-icons/fa';
import YoutubeThumbnail from '../../Shared/YoutubeThumbnail/YoutubeThumbnail';

const AllVideos = () => {
  const [topvideo, setTopVideo] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoPage, setVideoPage] = useState(1);

  // Get Letest Video---------------------
  useEffect(() => {
    fetch(`https://app.zufaa.tech/nagorik-tv/videos/${videoPage}/5`)
      .then((res) => res.json())
      .then((data) => {
        setTopVideo(data[0]);
        setVideos(data.slice(1));
      });
  }, [videoPage]);

  const handleVideo = (video) => {
    setTopVideo(video);
    window.scrollTo(0, 0);
  };

  // Handle Sirso news-------------------
  const handlerSirsoNewsLeft = () => {
    if (videoPage === 1) {
      setVideoPage(1);
      return;
    }

    setVideoPage(videoPage - 1);
  };
  const handlerSirsoNewsRight = () => {
    setVideoPage(videoPage + 1);
    if (videos?.length < 4) {
      setVideoPage(videoPage);
    }
  };

  return (
    <div className="my-5 w-11/12 lg:w-4/5 mx-auto ">
      <div className="grid lg:grid-cols-5 gap-20 md:gap-16 lg:gap-5 mb-20">
        <div className="col-span-3">
          {/* <h2 className='bg-gray px-2 py-1 text-white w-24'>রাজনীতি</h2> */}

          <div className="h-52 md:h-[430px] lg:h-[350px] xl:h-[380px] xxl:h-[400px] xxxl:h-[510px]">
            <iframe
              src={topvideo?.video_url}
              // width="1120" height="675"
              title="Nagorik TV"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="w-full h-full"
            ></iframe>
            <p className="text-base md:text-lg font-semibold p-3">
              {topvideo?.video_title}
            </p>
          </div>
        </div>

        <div className="col-span-3 lg:col-span-2 pt-12 lg:pt-0">
          <div className="flex justify-between">
            <h2 className=" text-xl font-bold text-red">পরবর্তী ভিডিও</h2>
            <div className="flex gap-1 items-center text-[#B8B8B8]">
              <FaLongArrowAltLeft
                className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
                onClick={handlerSirsoNewsLeft}
              />
              /
              <FaLongArrowAltRight
                className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
                onClick={handlerSirsoNewsRight}
              />
            </div>
          </div>

          <hr className="my-3 border-2 border-red " />
          <div className="grid grid-flow-row">
            {videos?.length ? (
              <div>
                {videos?.map((video, i) => (
                  <div key={i} className="w-full">
                    <div
                      onClick={() => handleVideo(video)}
                      className="grid grid-cols-3 gap-3 items-center cursor-pointer"
                    >
                      <div>
                        {video?.video_url?.split('/').pop() && (
                          <YoutubeThumbnail
                            videoUrl={video?.video_url}
                          ></YoutubeThumbnail>
                          // <img
                          //   src={`https://img.youtube.com/vi/${video?.video_url
                          //     ?.split('/')
                          //     .pop()}/maxresdefault.jpg`}
                          //   alt=""
                          //   className="w-full h-full object-cover"
                          // />
                        )}
                      </div>

                      <p className="text-xs sm:text-sm md:text-lg col-span-2">
                        {video?.video_title?.length > 70
                          ? video?.video_title?.slice(0, 70) + '...'
                          : video?.video_title}
                      </p>
                    </div>
                    {i + 1 === videos?.length ? (
                      <></>
                    ) : (
                      <hr className="col-span-3 my-2 lg:my-2 border-[#E2E2E2] lg:block" />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <h2 className="text-lg text-center font-semibold text-red ">
                  No More Videos........
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllVideos;
