import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCalendarAlt } from 'react-icons/fa';
import postImage from '../../src/Assets/images/sirso.png';
import axios from 'axios';

const Sidebar = ({ latestNews }) => {
  const [categories, setCategories] = useState([]);

  // const archives = [
  //   'Archive 2023',
  //   'Archive 2022',
  //   'Archive 2021',
  //   // Add more archive years here
  // ];

  // const categories = [
  //   'Top News',
  //   'Most Read',
  //   'Political',
  //   'Business',
  //   'Entertainment',
  //   // Add more categories here
  // ];

  // Get All Categoies----------------------------
  useEffect(() => {
    axios('https://app.zufaa.tech/nagorik-tv/categories/')
      .then((res) => {
        // console.log(res?.data);
        setCategories(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="sidebar w-full">
      <div className="sidebar-section">
        <hr className="border-red border"></hr>
        <h2 className="sidebar-section-title mt-3 text-xl text-red font-bold">
          Recent Posts
        </h2>
        <p className="text-[#B6B5B5] text-sm my-3">
          Seminar on Importance of Ethical Journalism & Challenges!
        </p>
        <div className="sidebar-recent-posts">
          {latestNews?.map((news, i) => (
            <Link to={`/singlenews/${news?.article_id}`} key={i} className="">
              <div className="grid grid-cols-3 md:grid-cols-1 lg:grid-cols-3 gap-5 md:gap-0 lg:gap-3 items-center md:items-start lg:items-center rounded-sm mb-0 md:mb-5 lg:mb-0">
                <img
                  src={news?.images[0]?.file_path}
                  alt=""
                  className="rounded-sm w-full object-cover h-16 md:h-28 lg:h-16"
                />
                <div className="md:mt-3 lg:mt-0 p-0 md:p-2 lg:p-0 col-span-2">
                  <div className="flex gap-2 mb-1">
                    <FaCalendarAlt className="text-[#908C8C]" />
                    <p className="text-xs md:text-base lg:text-sm">
                      {news?.publication_date}
                    </p>
                  </div>
                  <h2 className="md:text-sm">
                    {news?.title.length > 45
                      ? news?.title?.slice(0, 45) + '...'
                      : news?.title}
                  </h2>
                </div>
              </div>
              {i + 1 === latestNews?.length ? (
                <></>
              ) : (
                <hr className="col-span-3 my-3 lg:my-2 md:hidden bolck border-[#E2E2E2] lg:block" />
              )}
            </Link>
          ))}
        </div>
        {/* <hr className="border-red border mt-10 mb-5"></hr> */}
      </div>

      {/* <div className="sidebar-section">
        <h2 className="sidebar-section-title text-xl text-red font-bold">Archives</h2>
        <ul className="sidebar-list my-5">
          { archives.map( ( archive, index ) => (
            <li key={ index } className="sidebar-list-item text-sm text-[#3B5998] my-2">
              <Link className="sidebar-link  hover:text-sky transition-all duration-300">
                { archive }
              </Link>
            </li>
          ) ) }
        </ul>
      </div> */}

      <hr className="border-red border mt-10 mb-5"></hr>
      <div className="sidebar-section">
        <h2 className="sidebar-section-title text-xl text-red font-bold">
          Categories
        </h2>
        <ul className="sidebar-list my-5">
          {categories.map((category, index) => (
            <li
              key={index}
              className="sidebar-list-item text-sm text-[#3B5998] my-2"
            >
              <Link
                to={`/newscategory/${category?.name}/${category?.category_id}`}
                className="sidebar-link hover:text-sky transition-all duration-300"
              >
                {category?.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
