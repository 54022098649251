import React, { useEffect } from 'react';
import { FaAngleDown, FaBars, FaSearch, FaWifi } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../Assets/Logo/logo.png';
import axios from 'axios';
import { useState } from 'react';

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'react-headless-accordion';

const NavBar = () => {
  const [songbads, setSongbads] = useState([]);
  const [binodon, setBinodon] = useState([]);
  const [sports, setSports] = useState([]);
  const [anusthan, setAnusthan] = useState([]);
  const [propabs, setpropabs] = useState([]);

  const navigate = useNavigate();

  // Get all Categories--------------------
  useEffect(() => {
    axios('https://app.zufaa.tech/nagorik-tv/categories/')
      .then((res) => {
        // console.log( res.data );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Get Songbad SubCategory--------------
  useEffect(() => {
    axios(
      'https://app.zufaa.tech/nagorik-tv/categories/api/categorywithsub.php?categoryID=1'
    )
      .then((res) => {
        // console.log(res.data);
        setSongbads(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Get Binodon SubCategory--------------
  useEffect(() => {
    axios(
      'https://app.zufaa.tech/nagorik-tv/categories/api/categorywithsub.php?categoryID=3'
    )
      .then((res) => {
        // console.log( res.data );
        setBinodon(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Get Sports SubCategory--------------
  useEffect(() => {
    axios(
      'https://app.zufaa.tech/nagorik-tv/categories/api/categorywithsub.php?categoryID=4'
    )
      .then((res) => {
        // console.log( res.data );
        setSports(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Get Anusthan SubCategory--------------
  useEffect(() => {
    axios(
      'https://app.zufaa.tech/nagorik-tv/categories/api/categorywithsub.php?categoryID=5'
    )
      .then((res) => {
        // console.log( res.data );
        setAnusthan(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Get Probas SubCategory--------------
  useEffect(() => {
    axios(
      'https://app.zufaa.tech/nagorik-tv/categories/api/categorywithsub.php?categoryID=6'
    )
      .then((res) => {
        // console.log( res.data );
        setpropabs(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const rajnity = { category_id: 2, name: 'রাজনিতি' };

  // Large Device menu---------------------------
  const menuItems = (
    <>
      <Link
        to={'/'}
        className="lg:text-base  px-2 lg:px-0 py-2 lg:py-0 rounded-md lg:rounded-none"
      >
        হোম
      </Link>
      <div className="divider divider-horizontal mx-0 py-2"></div>

      <div className="dropdown lg:text-base  px-2 lg:px-0 py-2 lg:py-0 rounded-md lg:rounded-none">
        <label
          tabIndex={0}
          className="bg-transparent border-none flex justify-between items-center gap-2 cursor-pointer"
        >
          সংবাদ
          <FaAngleDown />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content mt-3 lg:mt-5 ml-0 lg:-ml-5 menu p-2 shadow rounded-sm w-40 gap-2 bg-yellow z-10 "
        >
          {songbads?.map((news, i) => (
            <li key={i} className="hover:bg-yellow rounded-sm">
              <Link to={`/category/${1}/${news?.id}`}>
                {news?.sub_category_name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="divider divider-horizontal mx-0 py-2"></div>

      <Link
        to={`/newscategory/${rajnity?.name}/${rajnity?.category_id}`}
        className="lg:text-base  px-2 lg:px-0 py-2 lg:py-0 rounded-md lg:rounded-none"
      >
        রাজনীতি
      </Link>

      <div className="divider divider-horizontal mx-0 py-2"></div>

      <div className="dropdown lg:text-base  px-2 lg:px-0 py-2 lg:py-0 rounded-md lg:rounded-none">
        <label
          tabIndex={0}
          className="bg-transparent border-none flex justify-between items-center gap-2 cursor-pointer"
        >
          বিনোদন
          <FaAngleDown />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content mt-3 lg:mt-5 ml-0 lg:-ml-5 menu p-2 shadow rounded-sm w-40 gap-2 bg-yellow z-10 "
        >
          {binodon?.map((news, i) => (
            <li key={i} className="hover:bg-yellow rounded-sm">
              <Link to={`/category/${3}/${news?.id}`}>
                {' '}
                {news?.sub_category_name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="divider divider-horizontal mx-0 py-2"></div>

      <div className="dropdown lg:text-base  px-2 lg:px-0 py-2 lg:py-0 rounded-md lg:rounded-none">
        <label
          tabIndex={0}
          className="bg-transparent border-none flex justify-between items-center gap-2 cursor-pointer"
        >
          খেলাধুলা
          <FaAngleDown />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content mt-3 lg:mt-5 ml-0 lg:-ml-5 menu p-2 shadow rounded-sm w-40 gap-2 bg-yellow z-10 "
        >
          {sports?.map((news, i) => (
            <li key={i} className="hover:bg-yellow rounded-sm">
              <Link to={`/category/${4}/${news?.id}`}>
                {' '}
                {news?.sub_category_name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="divider divider-horizontal mx-0 py-2"></div>

      <div className="dropdown lg:text-base  px-2 lg:px-0 py-2 lg:py-0 rounded-md lg:rounded-none">
        <label
          tabIndex={0}
          className="bg-transparent border-none flex justify-between items-center gap-2 cursor-pointer"
        >
          অনুষ্ঠান
          <FaAngleDown />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content mt-3 lg:mt-5 ml-0 lg:-ml-5 menu p-2 shadow rounded-sm w-auto gap-2 bg-yellow z-10 "
        >
          {anusthan?.map((news, i) => (
            <li key={i} className="hover:bg-yellow rounded-sm">
              <Link to={`/category/${5}/${news?.id}`}>
                {' '}
                {news?.sub_category_name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="divider divider-horizontal mx-0 py-2"></div>

      <div className="dropdown lg:text-base  px-2 lg:px-0 py-2 lg:py-0 rounded-md lg:rounded-none">
        <label
          tabIndex={0}
          className="bg-transparent border-none flex justify-between items-center gap-2 cursor-pointer"
        >
          প্রবাস
          <FaAngleDown />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content mt-3 lg:mt-5 ml-0 lg:-ml-5 menu p-2 shadow rounded-sm w-40 gap-2 bg-yellow z-10 "
        >
          {propabs?.map((news, i) => (
            <li key={i} className="hover:bg-yellow rounded-sm">
              <Link to={`/category/${6}/${news?.id}`}>
                {' '}
                {news?.sub_category_name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="divider divider-horizontal mx-0 py-2"></div>

      <Link to="/liveVideo" className="border-2 px-2 rounded-sm ">
        <div className="flex justify-between  text-2xl gap-2 text-red">
          <span className="uppercase font-bold text-xl">Live</span>
          <div className="flex items-center animate-ping">
            <FaWifi className="text-xl transform -rotate-90" />
            <FaWifi className="text-xl transform rotate-90 -ml-2" />
          </div>
        </div>
      </Link>
    </>
  );

  // Small Device Menu---------------------------
  const smallDeviceMenu = (
    <>
      <Accordion className="flex flex-col gap-3">
        <Link to={'/'} className="lg:text-base rounded-md lg:rounded-none">
          হোম
        </Link>

        <AccordionItem>
          <AccordionHeader className="w-full flex justify-between items-center">
            সংবাদ
            <FaAngleDown />
          </AccordionHeader>

          <AccordionBody className="rounded-md">
            {/* <div className="accordion-body flex flex-col gap-2">
              {songbads?.map((news, i) => (
                <li key={i} className="bg-yellow rounded-sm w-full">
                  <Link to={`/category/${1}/${news?.id}`}>
                    {news?.sub_category_name}
                  </Link>
                </li>
              ))}
            </div> */}
            <div>
              {songbads?.map((news, i) => (
                <li key={i} className="bg-yellow rounded-sm w-full">
                  <Link to={`/category/${1}/${news?.id}`}>
                    {news?.sub_category_name}
                  </Link>
                </li>
              ))}
            </div>
          </AccordionBody>
        </AccordionItem>

        <Link
          to={`/newscategory/${rajnity?.name}/${rajnity?.category_id}`}
          className="lg:text-base rounded-md lg:rounded-none"
        >
          রাজনীতি
        </Link>

        <AccordionItem>
          <AccordionHeader className="w-full flex justify-between items-center">
            বিনোদন
            <FaAngleDown />
          </AccordionHeader>

          <AccordionBody className="rounded-md">
            <div>
              {binodon?.map((news, i) => (
                <li key={i} className="bg-yellow rounded-sm">
                  <Link to={`/category/${3}/${news?.id}`}>
                    {' '}
                    {news?.sub_category_name}
                  </Link>
                </li>
              ))}
            </div>
          </AccordionBody>
        </AccordionItem>

        <AccordionItem>
          <AccordionHeader className="w-full flex justify-between items-center">
            খেলাধুলা
            <FaAngleDown />
          </AccordionHeader>

          <AccordionBody className="rounded-md">
            <div>
              {sports?.map((news, i) => (
                <li key={i} className="bg-yellow rounded-sm">
                  <Link to={`/category/${4}/${news?.id}`}>
                    {' '}
                    {news?.sub_category_name}
                  </Link>
                </li>
              ))}
            </div>
          </AccordionBody>
        </AccordionItem>

        <AccordionItem>
          <AccordionHeader className="w-full flex justify-between items-center">
            অনুষ্ঠান
            <FaAngleDown />
          </AccordionHeader>

          <AccordionBody className="rounded-md">
            <div>
              {anusthan?.map((news, i) => (
                <li key={i} className="bg-yellow rounded-sm">
                  <Link to={`/category/${5}/${news?.id}`}>
                    {' '}
                    {news?.sub_category_name}
                  </Link>
                </li>
              ))}
            </div>
          </AccordionBody>
        </AccordionItem>

        <AccordionItem>
          <AccordionHeader className="w-full flex justify-between items-center">
            প্রবাস
            <FaAngleDown />
          </AccordionHeader>

          <AccordionBody className="rounded-md">
            <div>
              {propabs?.map((news, i) => (
                <li key={i} className="bg-yellow rounded-sm">
                  <Link to={`/category/${6}/${news?.id}`}>
                    {' '}
                    {news?.sub_category_name}
                  </Link>
                </li>
              ))}
            </div>
          </AccordionBody>
        </AccordionItem>

        <Link to="/liveVideo" className="border-2 px-2 rounded-sm ">
          <div className="flex justify-between  text-2xl gap-2 text-red">
            <span className="uppercase font-bold text-xl">Live</span>
            <div className="flex items-center animate-ping">
              <FaWifi className="text-xl transform -rotate-90" />
              <FaWifi className="text-xl transform rotate-90 -ml-2" />
            </div>
          </div>
        </Link>
      </Accordion>
    </>
  );

  const handleSearch = (e) => {
    e.preventDefault();

    const search = e.target?.search?.value;
    navigate(`/search/${search}`);
  };

  return (
    <div className="bg-yellow py-2 lg:py-4 sticky top-0 z-50">
      <div className="flex justify-between items-center gap-2 w-11/12 lg:w-4/5 mx-auto">
        {/* small device--------------------
        ------------------------------------- */}
        <div className="lg:hidden flex justify-between items-center">
          {/* <img src={logo} alt="" className='w-1/3 object-cover' /> */}

          <div className="drawer drawer-end">
            <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content flex justify-between items-center">
              <Link to="/">
                <img src={logo} alt="" className="w-1/3 object-cover" />
              </Link>
              <label htmlFor="my-drawer-4" className="drawer-button ">
                <FaBars className="text-2xl md:text-4xl" />
              </label>
            </div>

            <div className="drawer-side z-50">
              <label htmlFor="my-drawer-4" className="drawer-overlay"></label>
              <ul className="menu p-4 w-56 md:w-80 bg-base-200 h-full mt-36 fixed -top-36 right-0 text-base-content">
                {/* <ul className="menu p-4 w-56 md:w-80 bg-base-200 h-full mt-36 fixed -top-36 right-0 text-base-content"> */}
                {/* {menuItems} */}
                {smallDeviceMenu}

                {/* <li><a>Sidebar Item 1</a></li>
                                <li><a>Sidebar Item 2</a></li> */}
              </ul>
            </div>
          </div>
        </div>

        {/* Large device------------------------
        ---------------------------------------- */}
        <div className="hidden lg:block">
          <div className="flex gap-3 font-bold items-center text-lg ">
            {menuItems}
          </div>
        </div>

        <div className="hidden lg:block">
          <form onSubmit={(e) => handleSearch(e)} className="flex">
            <input
              type="text"
              name="search"
              placeholder="Search ..."
              className="px-3 py-1 bg-[#fcf6c9] rounded-l-sm w-60 focus:outline-none"
            />
            <button type="submit" className="bg-gray p-2 rounded-r-sm">
              <FaSearch className="text-white" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
