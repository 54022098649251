import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { BsFillLightningFill } from 'react-icons/bs';

const NewsMarquee = () => {
  const [text, stText] = useState('');

  // Get all recent News headline----------------------------
  useEffect(() => {
    axios('https://app.zufaa.tech/nagorik-tv/latest-news/').then((res) => {
      //   console.log(res.data.body);
      const allNews = res.data.body;
      const concatenatedTitles = allNews
        .map((item) => item.title)
        .join('  * * *  ');
      stText(concatenatedTitles);
    });
  }, []);

  return (
    <div className="w-11/12 lg:w-4/5 mx-auto flex py-5">
      <div className="border-red bg-gray w-28 lg:w-32 flex items-center justify-center py-1 lg:py-2 px-2">
        <h2 className="text-white font-normal lg:font-bold flex items-center gap-1 lg:gap-2">
          <BsFillLightningFill className="text-red text-sm lg:text-lg" />
          <span className="text-xs lg:text-lg">সদ্য খবর :</span>
        </h2>
      </div>
      <Marquee
        play={true}
        direction="left"
        speed={60}
        pauseOnHover={true}
        autoFill={false}
        className="border-r-4 border-gray bg-white py-1 lg:py-2"
      >
        <p className="text-sm lg:text-lg font-medium mx-40 lg:mx-96">
          {' '}
          <span className="mr-0 lg:mr-96"></span> {`${text}`}{' '}
        </p>
      </Marquee>
    </div>
  );
};

export default NewsMarquee;
