import React from 'react';
import NavBar from '../../Shared/NavBar/NavBar';
import { Outlet } from 'react-router-dom';
import TopAppBar from '../../Shared/TopAppBar/TopAppBar';
import Footer from '../../Shared/Footer/Footer';
import LogoAds from '../../Shared/LogoAds/LogoAds';
import NewsMarquee from '../../Shared/NewsMarquee/NewsMarquee';

const Main = () => {
  return (
    <div>
      <TopAppBar></TopAppBar>
      <LogoAds></LogoAds>
      <NavBar></NavBar>
      <NewsMarquee></NewsMarquee>
      <Outlet></Outlet>
      <Footer></Footer>
    </div>
  );
};

export default Main;
