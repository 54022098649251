import React from 'react';
import SocialShare from '../../../Components/socialShare';
import ShareButtons from '../../../Shared/ShareButtons/ShareButtons';
import {
  FaCopy,
  FaFacebookF,
  FaShareAlt,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
const OnlineVote = () => {
  let imageUrl =
    'https://app.zufaa.tech/nagorik-tv/article_image/649697159b5fe.png';

  //   const copyLink = () => {
  //     let fullLink = `${imageUrl}`;
  //     navigator.clipboard.writeText(fullLink);
  //     alert('Image Link copied!');
  //   };
  const copyLink = (e) => {
    e.preventDefault();
    // console.log(shareUrl);
    navigator.clipboard.writeText(imageUrl);
    alert('Image Link copied to clipboard!');
  };

  return (
    <div>
      <div className="border border-[#E2E2E2] p-2 md:mt-0 lg:mt-10">
        <img src={imageUrl} alt="" />
        <div className="mt-3">
          {/* <SocialShare copyLink={ copyLink } imageUrl={ imageUrl } /> */}
          {/* <ShareButtons shareUrl={imageUrl} /> */}

          <div className="social-share flex gap-2 items-center md:mt-0">
            <span className="text-[#908C8C]">
              <FaShareAlt className="flex md:mr-2" />
            </span>
            <FacebookShareButton url={imageUrl}>
              <FaFacebookF className="text-fb h-7 w-7 border border-fb p-1 hover:ring-fb hover:ring-1 hover:ring-offset-1 transition-all duration-300" />
            </FacebookShareButton>
            <TwitterShareButton url={imageUrl}>
              <FaTwitter className="text-tw h-7 w-7 border border-tw p-1 hover:ring-tw hover:ring-1 hover:ring-offset-1 transition-all duration-300" />
            </TwitterShareButton>
            <WhatsappShareButton url={imageUrl}>
              <FaWhatsapp className="text-wapp h-7 w-7 border border-wapp p-1 hover:ring-wapp hover:ring-1 hover:ring-offset-1 transition-all duration-300" />
            </WhatsappShareButton>
            <button onClick={(e) => copyLink(e)}>
              <FaCopy className="text-[#908C8C] h-7 w-7 border border-[#908C8C]  p-1 hover:ring-[#908C8C] hover:ring-1 hover:ring-offset-1 transition-all duration-300" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineVote;
