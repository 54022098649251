import axios from 'axios';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { FaCalendarAlt, FaPlay, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Rajniy = () => {
  const [videos, setVideos] = useState([]);

  // Get Letest Video---------------------
  useEffect(() => {
    fetch('https://app.zufaa.tech/nagorik-tv/videos/1/1')
      .then((res) => res.json())
      .then((data) => {
        // console.log( data );
        setVideos(data?.slice(0, 1));
        window?.scrollTo(0, 0);
      });
  }, []);

  const [latestNews, setLatestNews] = useState([]);
  let getLatestNews = [];
  // Get Latest news-----------------------------
  useEffect(() => {
    axios('https://app.zufaa.tech/nagorik-tv/articlecount/1/4')
      .then((res) => {
        // console.log(res?.data);
        setLatestNews(res?.data);

        // const latestNews = res?.data?.body.slice(0, 4);

        // if (latestNews?.length) {
        //   for (const news of latestNews) {
        //     axios(
        //       `https://app.zufaa.tech/nagorik-tv/articleapi/${news?.article_id}`
        //     )
        //       .then((res) => {
        //         // console.log( res.data );

        //         getLatestNews.push(res?.data);
        //         const uniqueArray2 = getLatestNews.filter(
        //           (obj, index, self) => {
        //             return (
        //               index ===
        //               self.findIndex((o) => o.article_id === obj.article_id)
        //             );
        //           }
        //         );
        //         setLatestNews(uniqueArray2);
        //       })
        //       .catch((error) => {
        //         console.log(error);
        //       });
        //   }
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="lg:grid grid-cols-5 grid-rows-2 gap-5">
      <div className="col-span-3 row-span-2">
        {videos?.map((video, i) => (
          <div key={i} className="mb-5 lg:mb-0 row-span-2 ">
            {/* <div className="h-[200px] md:h-[430px] lg:h-[360px] xl:h-full"> */}
            <div>
              <Link to="/allvideos">
                <img
                  src={`https://img.youtube.com/vi/${video?.video_url
                    ?.split('/')
                    .pop()}/maxresdefault.jpg`}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </Link>

              {/* <iframe
                src={video?.video_url}
                width="1120" height="675"
                title="Nagorik TV"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="w-full h-full"
              ></iframe> */}
            </div>

            <p className="text-lg font-semibold pt-5">{video?.video_title}</p>

            {/* <Link to='/allvideos' className='text-2xl text-right mt-5 font-semibold text-sky flex items-center gap-1 justify-center px-5 mx-auto w-16 h-12 border rounded-full'><FaArrowRight /> </Link> */}

            <Link
              to="/allvideos"
              className="text-xl relative flex items-center justify-center p-4 px-4 py-2 overflow-hidden font-medium w-16 mx-auto transition duration-500 ease-out border border-red rounded-full shadow-md group"
            >
              <span className="absolute inset-0 flex items-center justify-center w-full h-full text-red duration-300 -translate-x-full  group-hover:translate-x-0 ease">
                <FaPlay />
              </span>
              <span className="absolute flex items-center justify-center w-full h-full text-red transition-all duration-300 transform group-hover:translate-x-full ease">
                <FaArrowRight />
              </span>
              <span className="relative invisible">
                <FaArrowRight />
              </span>
            </Link>
          </div>
        ))}
      </div>
      <div className="col-span-2 row-span-2 hidden lg:block">
        <div className="grid grid-cols-2 gap-5">
          {latestNews?.map((news, i) => (
            <Link
              key={i}
              to={`/singlenews/${news?.article_id}`}
              className="carousel-link"
            >
              <div className="carousel-card flex flex-col shadow rounded-sm">
                <img
                  src={news?.images[0]?.file_path}
                  alt="news"
                  className="carousel-image object-cover rounded-sm"
                  //   className="carousel-image h-28 lg:h-28 xl:h-40 xxl:h-60 object-cover rounded-sm"

                  //   className="carousel-image h-28 xl:h-32 object-cover rounded-sm"
                />
                <div className="carousel-card-info p-2">
                  <div className="flex items-center gap-2 text-[#B8B8B8] text-sm">
                    <div>
                      <FaCalendarAlt />
                    </div>
                    {news?.publication_date && (
                      <p className="text-xs md:text-base lg:text-sm">
                        {format(
                          new Date(news?.publication_date),
                          'MMM-dd-yyyy'
                        )}
                      </p>
                    )}
                  </div>
                  <div className="text-black h-[70px] xl:h-20 text-xs md:text-lg lg:text-base xl:text-lg text-justify">
                    {news?.title?.length > 55
                      ? news?.title?.slice(0, 55) + '...'
                      : news?.title}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rajniy;
