import React, { useState, useEffect } from 'react';
// import SocialShare from '../../Components/ShareForPoll/PollShare';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ShareButtons from '../../Shared/ShareButtons/ShareButtons';

const VotingSinglePage = ({ copyLink }) => {
  const [poll, setPoll] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [refresh, setRefresh] = useState(false);
  const id = useParams();
  const [ipAddress, setIPAddress] = useState();

  // Get IP address of a PC-------------------------------
  const getIPAddress = localStorage.getItem('IPAddress');

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        const ipAddress = data.ip;
        // localStorage.setItem('IPAddress', ipAddress)
        setIPAddress(ipAddress);
        // Perform further actions with the IP address
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  // get single vote--------------
  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };
  // get Final vote--------------------------
  const handleVote = ([e, poll]) => {
    e.preventDefault();
    // console.log( poll );
    // console.log( selectedOption );
    const findKeyByValue = (poll, value) => {
      for (let [key, val] of Object.entries(poll)) {
        if (val === value) {
          return { key, val };
        }
      }
      return null;
    };
    const desiredValue = selectedOption;
    const foundPair = findKeyByValue(poll, desiredValue);
    // vote poll calculation---------------------------
    const newPoll = {
      id: poll?.id,
      question: poll?.question,
      option_1: poll?.option_1,
      option_2: poll?.option_2,
      option_3: poll?.option_3,
      option_1_vote: poll?.option_1_vote,
      option_2_vote: poll?.option_2_vote,
      option_3_vote: poll?.option_3_vote,
      publication_date: poll?.publication_date,
      image: poll?.image,
    };
    // console.log( newPoll );
    if (foundPair.key === 'option_1') {
      newPoll.option_1_vote = poll?.option_1_vote + 1;
      // console.log( newPoll );
      pollCount(newPoll);
      return;
    } else if (foundPair.key === 'option_2') {
      newPoll.option_2_vote = poll?.option_2_vote + 1;
      // console.log( newPoll );
      pollCount(newPoll);
      return;
    } else if (foundPair.key === 'option_3') {
      newPoll.option_3_vote = poll?.option_3_vote + 1;
      // console.log( newPoll );
      pollCount(newPoll);
      return;
    }
  };
  const pollCount = (poll) => {
    const url = `https://app.zufaa.tech/nagorik-tv/polls/${poll?.id}`;
    axios
      .put(url, poll)
      .then((res) => {
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Get All Polls----------------------------
  useEffect(() => {
    fetch(`https://app.zufaa.tech/nagorik-tv/polls/${id?.id}`)
      .then((res) => res.json())
      .then((data) => {
        setPoll(data);
      });
  }, [id, refresh]);

  let PollUrl = `${window.location.origin}/voting/${poll?.id}`;
  //   console.log(PollUrl);

  const shareUrlWithData = `${poll.question}\nOptions:\n1. ${poll.option_1}: ${poll.option_1_percentage}%\n2. ${poll.option_2}: ${poll.option_2_percentage}%\n3. ${poll.option_3}: ${poll.option_3_percentage}%\n${PollUrl}`;
  //   console.log(shareUrlWithData);

  return (
    <div className="mt-14 mb-14 w-96 mx-auto my-5">
      <div className="flex justify-between"></div>
      <div>
        <div className="bg-white shadow rounded-sm mt-5">
          <img
            src={poll?.image}
            alt="voting"
            className="w-full h-64 object-cover rounded-sm"
          />
          <form className="p-5">
            <h2 className="text-base font-semibold mt-4 mb-8">
              {poll?.question}
            </h2>
            {/* { Object.keys( voteLabels ).map( ( vote ) => ( */}
            {/* Option_1 Poll--------------------- */}
            <div className="relative w-full bg-[#E7E5E5] h-9 rounded overflow-hidden mb-4 p-1">
              <div
                style={{ width: `${poll?.option_1_percentage}%` }}
                className={`absolute inset-0 transition-all duration-500 ease-in-out bg-sky `}
              />
              <div className="absolute inset-0 flex items-center justify-between">
                <label className="flex items-center gap-2 ml-2">
                  <input
                    type="radio"
                    value={poll?.option_1}
                    checked={selectedOption === `${poll?.option_1}`}
                    onChange={handleRadioChange}
                    className="w-5 h-5"
                    disabled={getIPAddress === ipAddress}
                  />
                  <span className="text-black text-xs">{poll?.option_1}</span>
                </label>
                <span className="text-black text-xs mr-2">{`${poll?.option_1_percentage}%`}</span>
              </div>
            </div>
            {/* Option_2 Poll--------------------- */}
            <div className="relative w-full bg-[#E7E5E5] h-9 rounded overflow-hidden mb-4 p-1">
              <div
                style={{ width: `${poll?.option_2_percentage}%` }}
                className={`absolute inset-0 transition-all duration-500 ease-in-out bg-sky `}
              />
              <div className="absolute inset-0 flex items-center justify-between">
                <label className="flex items-center gap-2 ml-2">
                  <input
                    type="radio"
                    value={poll?.option_2}
                    checked={selectedOption === `${poll?.option_2}`}
                    onChange={handleRadioChange}
                    className="w-5 h-5"
                    disabled={getIPAddress === ipAddress}
                  />
                  <span className="text-black text-xs">{poll?.option_2}</span>
                </label>
                <span className="text-black text-xs mr-2">{`${poll?.option_2_percentage}%`}</span>
              </div>
            </div>
            {/* Option_3 Poll--------------------- */}
            <div className="relative w-full bg-[#E7E5E5] h-9 rounded overflow-hidden mb-4 p-1">
              <div
                style={{ width: `${poll?.option_3_percentage}%` }}
                className={`absolute inset-0 transition-all duration-500 ease-in-out bg-sky `}
              />
              <div className="absolute inset-0 flex items-center justify-between">
                <label className="flex items-center gap-2 ml-2">
                  <input
                    type="radio"
                    value={poll?.option_3}
                    checked={selectedOption === `${poll?.option_3}`}
                    onChange={handleRadioChange}
                    className="w-5 h-5"
                    disabled={getIPAddress === ipAddress}
                  />
                  <span className="text-black text-xs">{poll?.option_3}</span>
                </label>
                <span className="text-black text-xs mr-2">{`${poll?.option_3_percentage}%`}</span>
              </div>
            </div>
            <div className="flex justify-between items-center  gap-4 mt-4">
              <button
                onClick={(e) => handleVote([e, poll])}
                className="px-4 py-2 rounded bg-sky text-white hover:bg-blue-600 focus:outline-none"
                // disabled={ userVote === null }
                disabled={getIPAddress === ipAddress}
              >
                ভোট দিন
              </button>
              {/* <SocialShare copyLink={copyLink} votePoll={poll} /> */}
              <ShareButtons
                shareUrl={PollUrl}
                shareUrlWithData={shareUrlWithData}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default VotingSinglePage;
