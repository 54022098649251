import React, { useEffect } from 'react';
import logo from '../../Assets/Logo/category.png';
import { FaChevronCircleRight, FaHome } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';

const SearchNews = () => {
  const [allNews, setAllNews] = useState([]);
  const { search } = useParams();

  // Get All Search News-------------------------
  useEffect(() => {
    axios('https://app.zufaa.tech/nagorik-tv/articles/')
      .then((res) => {
        const allArticle = res?.data;
        const searchNews = allArticle?.filter((article) =>
          article?.title.includes(search)
        );
        setAllNews(searchNews);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [search]);

  // Get All Videos-----------------------
  // useEffect(() => {
  //   axios('https://app.zufaa.tech/nagorik-tv/videos/1/100')
  //     .then(res => {
  //       console.log(res?.data);
  //       const allVideos = res?.data;
  //       const searchNews = allVideos?.filter(video => video?.video_title.includes(search));
  //       console.log(searchNews);
  //       setAllNews([...allNews, searchNews])
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     })
  // }, [search])

  return (
    <div className="w-11/12 lg:w-4/5 mx-auto mt-2 pb-20">
      <div className="flex justify-between items-baseline">
        <h2 className="text-lg md:text-2xl lg:text-3xl font-bold mb-3 ">
          <span className="text-sky">{search}</span> - search results
        </h2>
        <div className="flex justify-end w-1/2">
          <img src={logo} alt="" className="w-1/4" />
        </div>
      </div>
      <hr className="mb-3 -mt-3 md:-mt-2 border md:border-b-2 lg:border-b-4" />
      <div className="flex gap-1 text-[#808080] md:text-lg items-center">
        <FaHome /> /
        <Link to="/" className="hover:text-sky duration-500">
          হোম
        </Link>{' '}
        /<h2 className="text-sky">{search}</h2>
      </div>
      <hr className="my-3 border-b-1 border-[#808080]" />

      {allNews?.length ? (
        // console.log(allArticle)
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-8">
          {allNews?.map((news, i) => (
            <div key={i} className="bg-white rounded-sm">
              {/* <img src={news?.images[0]?.file_path} alt="" className='rounded-t-sm h-56 w-full object-cover' /> */}
              <div className="flex flex-col gap-3 p-5">
                <div className="flex gap-5">
                  <h2>
                    Posted by <span className="text-[#3B5998]">NagorikTV</span>
                  </h2>
                  <p className="text-[#3B5998]">{news?.publication_date}</p>
                </div>
                <h2 className="text-xl font-semibold">{news?.title}</h2>
                <p>
                  {news?.content?.length > 200
                    ? news?.content?.slice(0, 200) + '....'
                    : news?.content}
                </p>

                <Link
                  to={`/singlenews/${news?.article_id}`}
                  className="text-lg text-[#3B5998] font-semibold  flex gap-2 justify-end items-center"
                >
                  <span> Read More</span>
                  <FaChevronCircleRight className="text-xl" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <h2 className="text-xl font-bold text-center my-24">
          No News is Uploaded Yet..........
        </h2>
      )}
    </div>
  );
};

export default SearchNews;
