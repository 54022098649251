import React from 'react';
import footerlogo from '../../Assets/Logo/footerlogo.jpg';

import {
  FaChevronRight,
  FaHome,
  FaPhone,
  FaEnvelope,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaCalendarAlt,
} from 'react-icons/fa';

import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';

const Footer = () => {
  const [newses, setNewses] = useState([]);
  const [categories, setCategories] = useState([]);

  const url = 'https://app.zufaa.tech/nagorik-tv/articlecount/1/4';
  useEffect(() => {
    axios(url)
      .then((res) => {
        // console.log( res );
        setNewses(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Get All Categories-------------------
  useEffect(() => {
    axios('https://app.zufaa.tech/nagorik-tv/categories/')
      .then((res) => {
        // console.log(res?.data);
        setCategories(res?.data?.slice(0, 6));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="bg-gray text-white">
      <div className="w-4/5 mx-auto pt-10 pb-0">
        <footer className="md:flex md:flex-wrap md:justify-between gap-5">
          {/* <footer className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5"> */}

          {/* Logo SEction----------------------------- */}
          <div className="flex flex-col gap-3 w-full md:w-2/5 lg:w-1/5 text-sm sm:pb-10">
            <img className="w-full md:w-32" src={footerlogo} alt="" />
            <p className="text-justify">
              টেলিভিশন জীবনের ছবি চোখের সামনে সমাজের নানা সঙ্গতি অসঙ্গতি তুলে
              ধরে। আমরা অনুভব করতে পারি জীবন কোন পথে এগুচ্ছে, আমাদের প্রিয়
              বাংলাদেশের প্রকৃত চিত্র ধরা পড়বে আমাদের নাগরিক টিভিতে, আমরা
              আয়নার আদলে দেখবো সমাজ, দেশ ও আন্তর্জাতিক বিশ্ব।
            </p>
            <div className="flex gap-3 text-5xl">
              <a
                href="https://www.facebook.com/NagorikTV/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                <FaFacebookF className="bg-[#424242] hover:text-red duration-500 text-white text-2xl p-1 rounded-sm" />
              </a>
              <a
                href="https://twitter.com/NagorikTV"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter className="bg-[#424242] hover:text-red duration-500 text-white text-2xl p-1 rounded-sm" />
              </a>
              <a
                href="https://youtube.com/c/NagorikTV"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube className="bg-[#424242] hover:text-red duration-500 text-white text-2xl p-1 rounded-sm" />
              </a>
            </div>

            <div className="flex gap-6 pt-3 mb-0">
              <Link className="hover:text-red transition-colors">
                Terms and Conditions
              </Link>
              <Link className="hover:text-red transition-colors">
                Privacy Policy
              </Link>
            </div>
          </div>

          {/* useful Links----------------------------- */}
          <div className="flex flex-col gap-4 w-full md:w-1/2 lg:w-1/5 text-sm sm:pb-10 ">
            <h2 className="footer-title text-white mt-8 md:mt-0">
              USEFUL LINKS
            </h2>
            <Link
              to="/"
              className="hover:text-red transition-colors flex items-center gap-2 text-[#B6B5B5] border-b border-dashed pb-1"
            >
              <FaChevronRight />
              হোম
            </Link>

            {categories?.map((category, i) => (
              <Link
                to={`/newscategory/${category?.name}/${category?.category_id}`}
                key={i}
                className="hover:text-red transition-colors flex items-center gap-2 text-[#B6B5B5] border-b border-dashed pb-1"
              >
                <FaChevronRight />
                {category?.name}
              </Link>
            ))}

            {/* // <Link className="hover:text-red transition-colors flex items-center gap-2 text-[#B6B5B5] border-b border-dashed pb-1">
            //   <FaChevronRight />
            //   নাগরিক সংবাদ
            // </Link>
            // <Link className="hover:text-red transition-colors flex items-center gap-2 text-[#B6B5B5] border-b border-dashed pb-1">
            //   <FaChevronRight />
            //   টকশো
            // </Link>
            // <Link className="hover:text-red transition-colors flex items-center gap-2 text-[#B6B5B5] border-b border-dashed pb-1">
            //   <FaChevronRight />
            //   যোগাযোগ
            // </Link>
            // <Link className="hover:text-red transition-colors flex items-center gap-2 text-[#B6B5B5] border-b border-dashed pb-1">
            //   <FaChevronRight />
            //   নাগরিক কোর টিম
            // </Link> */}
          </div>

          {/* Copany address----------------------------- */}
          <div className="flex flex-col gap-3 w-full md:w-1/2 lg:w-1/4 text-sm sm:pb-10">
            <h2 className="footer-title mt-8 md:mt-0">Company</h2>

            <div className="flex gap-2 items-start">
              <div>
                <FaHome className="bg-red p-1 w-6 h-6 rounded-sm text-white" />
              </div>
              <div>
                <p className="text-[#B6B5B5]">
                  <span className="text-white font-semibold mr-2">
                    Corporate Office :
                  </span>
                  37-48 72nd Street 2nd Fl Jackson Heights NY 11372
                </p>
              </div>
            </div>

            <div className="flex gap-2 items-start">
              <div>
                <FaPhone className="bg-red p-1 w-6 h-6 rounded-sm text-white" />
              </div>
              <div>
                <p className="text-[#B6B5B5]">917-722-1405</p>
              </div>
            </div>

            <div className="flex gap-2 items-start">
              <div>
                <FaHome className="bg-red p-1 w-6 h-6 rounded-sm text-white" />
              </div>
              <div>
                <p className="text-[#B6B5B5]">
                  <span className="text-white font-semibold">
                    Montreal Studio:
                  </span>{' '}
                  950 Ogilvy Avenue, #08, Montréal, Québec - H3N 1P4, Canada
                </p>
              </div>
            </div>

            <div className="flex gap-2 items-start">
              <div>
                <FaPhone className="bg-red p-1 w-6 h-6 rounded-sm text-white" />
              </div>
              <div>
                <p className="text-[#B6B5B5]">514-500-5012</p>
              </div>
            </div>

            <div className="flex gap-2 items-start">
              <div>
                <FaEnvelope className="bg-red p-1 w-6 h-6 rounded-sm text-white" />
              </div>
              <div>
                <p className="text-[#B6B5B5]">info@nagoriktv.com</p>
              </div>
            </div>
          </div>

          {/* Latest News------------------------ */}
          <div className="flex flex-col gap-3 w-full md:w-2/5 lg:w-1/4 text-sm sm:pb-10">
            <h2 className="footer-title mt-8 md:mt-0">LATEST NEWS</h2>

            {newses?.map((news, i) => (
              <Link
                to={`/singlenews/${news?.article_id}`}
                key={i}
                className="grid grid-cols-3 gap-5"
              >
                <div className=" overflow-hidden">
                  <img
                    src={news?.images[0].file_path}
                    alt=""
                    className="w-full object-cover h-16"
                  />
                </div>
                <div className="col-span-2">
                  <div>
                    <p className="flex gap-2 text-[#B6B5B5]">
                      <FaCalendarAlt /> {news?.publication_date}
                    </p>
                  </div>
                  <div className="hover:text-red transition-colors">
                    {news?.title.length > 40
                      ? news?.title.slice(0, 40) + '....'
                      : news?.title}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </footer>
      </div>

      <hr className="my-5" />
      <div className="w-4/5 mx-auto pb-3">
        <p className="text-center">
          NagorikTV © 2023 | Theme Design & Developed By NagorikSolution
        </p>
      </div>
    </div>
  );
};

export default Footer;
