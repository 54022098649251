import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

const SocialMediaFollowes = () => {
  return (
    <div>
      <hr className="border-b border-red mt-5" />
      <h2 className="text-red text-xl font-semibold py-2">সংযুক্ত থাকুন</h2>

      <div className="bg-white py-5 px-3 border border-[#E2E2E2]">
        <div className="grid grid-cols-4 gap-2">
          <div className="flex flex-col items-center justify-center">
            <div className="border p-3 rounded-full border-fb">
              <FaFacebookF className="text-3xl text-fb" />
            </div>
            <div className="text-center">
              <h2 className="font-semibold mt-3">206k</h2>
              <p className="text-sm md:text-base lg:text-xs text-left text-[#908C8C] font-semibold">
                LIKES
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <div className="border p-3 rounded-full border-tw">
              <FaTwitter className="text-3xl text-tw" />
            </div>
            <div className="text-center">
              <h2 className="font-semibold mt-3">1823</h2>
              <p className="text-sm lg:text-xs text-left text-[#908C8C] font-semibold">
                FOLLOW
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <div className="border p-3 rounded-full border-[#F70071]">
              <FaInstagram className="text-3xl text-[#F70071]" />
            </div>
            <div className="text-center">
              <h2 className="font-semibold mt-3">353</h2>
              <p className="text-sm lg:text-xs text-left text-[#908C8C] font-semibold">
                FOLLOW
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <div className="border p-3 rounded-full border-red">
              <FaYoutube className="text-3xl text-red" />
            </div>
            <div className="text-center">
              <h2 className="font-semibold mt-3">411k</h2>
              <p className="text-sm lg:text-xs text-left text-[#908C8C] font-semibold">
                SUBSCRIBE
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaFollowes;
