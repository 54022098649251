import React from 'react';
import {
  FaCalendarAlt,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
} from 'react-icons/fa';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const EditorLikes = () => {
  const [editorLikes, setEditorLikes] = useState([]);
  const [editorPage, setEditorPage] = useState(1);

  // Handle Editors news-------------------
  const handleEditorLikesNewsLeft = () => {
    if (editorPage === 1) {
      setEditorPage(1);
      return;
    }

    setEditorPage(editorPage - 1);
  };

  const handleEditorLikesNewsRight = () => {
    setEditorPage(editorPage + 1);

    // console.log(sportsNews?.length);
    if (editorLikes?.length < 3) {
      setEditorPage(editorPage);
    }
  };

  // Get All editorLikes news----------------------
  useEffect(() => {
    axios(
      `https://app.zufaa.tech/nagorik-tv/category-article/12/${editorPage}/3`
    )
      .then((res) => {
        // console.log(res.data);
        setEditorLikes(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [editorPage]);
  return (
    <div className="mt-14">
      <hr className="border-b border-red" />
      <div className="flex justify-between">
        <h2 className="text-red text-xl font-bold py-3">সম্পাদকের পছন্দ</h2>
        <div className="flex gap-1 items-center text-[#B8B8B8]">
          <FaLongArrowAltLeft
            onClick={handleEditorLikesNewsLeft}
            className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
          />
          /
          <FaLongArrowAltRight
            onClick={handleEditorLikesNewsRight}
            className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
          />
        </div>
      </div>

      <Link
        to={`/singlenews/${editorLikes[0]?.article_id}`}
        className="relative cursor-pointer duration-500 hover:gap-5 rounded-md card hover:z-20"
      >
        <img
          src={editorLikes[0]?.images[0].file_path}
          alt=""
          className="rounded-sm "
        />
        <div className="newsoverlay">
          <div className="text-white p-5 absolute bottom-0">
            <div className="flex gap-2 items-center">
              <FaCalendarAlt className="text-sm" />
              {editorLikes[0]?.publication_date && (
                <p className="text-sm ">
                  {format(
                    new Date(editorLikes[0]?.publication_date),
                    'MMM-dd-yyyy'
                  )}
                </p>
              )}
            </div>
            <h2 className="text-xs lg:text-base">{editorLikes[0]?.title}</h2>
          </div>
        </div>
      </Link>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
        {editorLikes?.map((news, i) => (
          <Link
            to={`/singlenews/${news?.article_id}`}
            key={i}
            className="relative cursor-pointer duration-500 hover:gap-5 rounded-md card hover:z-20"
          >
            <img
              src={news?.images[0]?.file_path}
              alt=""
              className="rounded-sm object-cover"
            />

            <div className="single_cardoverlay">
              <div className="text-white p-2 absolute bottom-0">
                <div className="flex gap-2 items-baseline">
                  <FaCalendarAlt className="text-sm text-[#908C8C]" />
                  {news?.publication_date && (
                    <p className="text-xs md:text-base lg:text-sm">
                      {format(new Date(news?.publication_date), 'MMM-dd-yyyy')}
                    </p>
                  )}
                </div>
                <h2 className="">
                  {news?.title.length > 40
                    ? news?.title.slice(0, 40) + ['..']
                    : news?.title}
                </h2>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default EditorLikes;
