import React from 'react';

import { Link } from 'react-router-dom';
import {
  FaCalendarAlt,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
} from 'react-icons/fa';

import './RecentNews.css';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { format } from 'date-fns';

const RecentNews = () => {
  const [recentNews, setRecentNews] = useState([]);
  const [recentPage, setRecentPage] = useState(1);
  const [sportsNews, setSportsNews] = useState([]);
  const [sportPage, setSportPage] = useState(1);

  // Handle Recent news-------------------
  const handleRecentNewsLeft = () => {
    if (recentPage === 1) {
      setRecentPage(1);
      return;
    }

    setRecentPage(recentPage - 1);
  };

  const handleRecentNewsRight = () => {
    setRecentPage(recentPage + 1);

    if (recentNews?.length < 4) {
      setRecentPage(recentPage);
    }
  };

  // Get All Recent News---------------------
  useEffect(() => {
    axios(
      `https://app.zufaa.tech/nagorik-tv/category-article/10/${recentPage}/4`
    )
      .then((res) => {
        // console.log(res.data);
        setRecentNews(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [recentPage]);

  // Handle Sprots news-------------------
  const handleSprotsNewsLeft = () => {
    if (sportPage === 1) {
      setSportPage(1);
      return;
    }

    setSportPage(sportPage - 1);
  };

  const handleSprotsNewsRight = () => {
    setSportPage(sportPage + 1);

    // console.log(sportsNews?.length);
    if (sportsNews?.length < 4) {
      setSportPage(sportPage);
    }
  };

  // Get Sports News---------------------
  useEffect(() => {
    axios(`https://app.zufaa.tech/nagorik-tv/category-article/4/${sportPage}/4`)
      .then((res) => {
        // console.log(res.data);
        setSportsNews(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [sportPage]);

  //   const handlerArrowLeft = () => {
  //     console.log( 'object' );
  //   }
  //   const handlerArrowRight = () => {
  //     console.log( 'object' );
  //   }

  return (
    <div className="lg:grid grid-cols-2 gap-5 mt-10 md:mt-20">
      <div to="/singlenews">
        <hr className="border-b border-red" />
        <div className="flex justify-between">
          <h2 className="text-red text-xl font-bold py-3">সাম্প্রতিক সংবাদ</h2>
          <div className="flex gap-1 items-center text-[#B8B8B8]">
            <FaLongArrowAltLeft
              onClick={handleRecentNewsLeft}
              className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
            />
            /
            <FaLongArrowAltRight
              onClick={handleRecentNewsRight}
              className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
            />
          </div>
        </div>

        {/* Recent News------------ */}
        <Link
          to={`/singlenews/${recentNews[0]?.article_id}`}
          className="relative cursor-pointer duration-500 hover:gap-5 rounded-md card hover:z-20"
        >
          <img
            src={recentNews[0]?.images[0].file_path}
            alt=""
            className="rounded-sm object-cover"
          />

          <div className="single_cardoverlay">
            <div className="text-white p-5 absolute bottom-0">
              <div className="flex gap-2 items-baseline">
                <FaCalendarAlt className="text-sm" />
                {recentNews[0]?.publication_date && (
                  <p className="text-sm ">
                    {format(
                      new Date(recentNews[0]?.publication_date),
                      'MMM-dd-yyyy'
                    )}
                  </p>
                )}
              </div>
              <h2 className="">{recentNews[0]?.title}</h2>
            </div>
          </div>
        </Link>

        <hr className="my-5 outline-1" />

        <div className="grid grid-cols-2 gap-5">
          {recentNews?.map((news, i) => (
            <Link
              to={`/singlenews/${news?.article_id}`}
              key={i}
              className="shadow rounded-sm"
            >
              <img
                src={news?.images[0]?.file_path}
                alt=""
                className="rounded-sm object-cover w-full"
              />
              <div className="mt-2 p-2">
                <div className="flex gap-2 text-[#908C8C]">
                  <FaCalendarAlt className="text-sm text-[#908C8C]" />
                  {news?.publication_date && (
                    <p className="text-xs md:text-base lg:text-sm">
                      {format(new Date(news?.publication_date), 'MMM-dd-yyyy')}
                    </p>
                  )}
                </div>
                <h2 className="text-sm text-justify">
                  {news?.title.length > 50
                    ? news?.title?.slice(0, 50) + '...'
                    : news?.title}
                </h2>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* Sports News----------------- */}
      <div className="lg:mt-0 mt-14">
        <hr className="border-b border-red" />
        <div className="flex justify-between">
          <h2 className="text-red text-xl font-bold py-3">খেলার খবর</h2>

          <div className="flex gap-1 items-center text-[#B8B8B8]">
            <FaLongArrowAltLeft
              onClick={handleSprotsNewsLeft}
              className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
            />
            /
            <FaLongArrowAltRight
              onClick={handleSprotsNewsRight}
              className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
            />
          </div>
        </div>
        <Link
          to={`/singlenews/${sportsNews[0]?.article_id}`}
          className="relative cursor-pointer duration-500 hover:gap-5 rounded-md card hover:z-20"
        >
          <img
            src={sportsNews[0]?.images[0].file_path}
            alt=""
            className="rounded-sm object-cover"
          />

          <div className="single_cardoverlay">
            <div className="text-white p-5 absolute bottom-0">
              <div className="flex gap-2 items-baseline">
                <FaCalendarAlt className="text-sm" />
                {sportsNews[0]?.publication_date && (
                  <p className="text-sm ">
                    {format(
                      new Date(sportsNews[0]?.publication_date),
                      'MMM-dd-yyyy'
                    )}
                  </p>
                )}
              </div>
              <h2 className="">{sportsNews[0]?.title}</h2>
            </div>
          </div>
        </Link>

        <hr className="my-5 outline-1" />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-5 lg:gap-0 lg:grid-cols-1">
          {sportsNews?.map((news, i) => (
            <Link to={`/singlenews/${news?.article_id}`} key={i} className="">
              <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-0 lg:gap-5 items-center md:items-start lg:items-center rounded-sm">
                <img
                  src={news?.images[0]?.file_path}
                  alt=""
                  className="rounded-sm w-full md:col-span-2 lg:col-span-1 object-cover"
                />

                <div className="md:mt-3 lg:mt-0 p-0 md:p-2 lg:p-0 col-span-2">
                  <div className="flex gap-2 mb-1 text-[#908C8C]">
                    <FaCalendarAlt className="" />
                    {news?.publication_date && (
                      <p className="text-xs md:text-base lg:text-sm">
                        {format(
                          new Date(news?.publication_date),
                          'MMM-dd-yyyy'
                        )}
                      </p>
                    )}
                  </div>

                  <h2>
                    {news?.title.length > 60
                      ? news?.title?.slice(0, 60) + '...'
                      : news?.title}
                  </h2>
                </div>
              </div>
              {i + 1 === sportsNews?.length ? (
                <></>
              ) : (
                <hr className="col-span-3 my-3 lg:my-4 md:hidden bolck border-[#E2E2E2] lg:block" />
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecentNews;
