import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaCalendarAlt,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
} from 'react-icons/fa';
import './AlocitoNews.css';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { format } from 'date-fns';

const AlocitoNews = () => {
  const [sirsoNews, setSirsoNews] = useState([]);
  const [sirsoPage, setSirsoPage] = useState(1);
  const [alocitoNews, setAlocitoNews] = useState([]);
  const [alocitoPage, setAlocitoPage] = useState(1);

  // Handle Sirso news-------------------
  const handlerSirsoNewsLeft = () => {
    if (sirsoPage === 1) {
      setSirsoPage(1);
      return;
    }

    setSirsoPage(sirsoPage - 1);
  };

  const handlerSirsoNewsRight = () => {
    setSirsoPage(sirsoPage + 1);

    console.log(sirsoNews?.length);
    if (sirsoNews?.length < 4) {
      setSirsoPage(sirsoPage);
    }
  };

  // Get Sirso News---------------------
  useEffect(() => {
    axios(`https://app.zufaa.tech/nagorik-tv/category-article/7/${sirsoPage}/4`)
      .then((res) => {
        // console.log(res.data);
        setSirsoNews(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [sirsoPage]);

  // Handle Alocito news-----------------
  const handleAlocitoLeft = () => {
    if (alocitoPage === 1) {
      setAlocitoPage(1);
      return;
    }

    setAlocitoPage(alocitoPage - 1);
  };
  const handleAlocitoRight = () => {
    setAlocitoPage(alocitoPage + 1);

    if (alocitoNews?.length < 4) {
      setAlocitoPage(alocitoPage);
    }
  };
  // Get Alocito News---------------------
  useEffect(() => {
    axios(
      `https://app.zufaa.tech/nagorik-tv/category-article/9/${alocitoPage}/4`
    ).then((res) => {
      // console.log( res?.data );
      setAlocitoNews(res?.data);
    });
  }, [alocitoPage]);

  return (
    <div className="lg:grid grid-cols-2 gap-5">
      {/* Sirso News---------------------------- */}
      <div>
        <hr className="border-b border-red" />

        <div className="flex justify-between">
          <h2 className="text-red text-xl font-bold py-3">শীর্ষ সংবাদ</h2>
          <div className="flex gap-1 items-center text-[#B8B8B8]">
            <FaLongArrowAltLeft
              className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
              onClick={handlerSirsoNewsLeft}
            />
            /
            <FaLongArrowAltRight
              className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
              onClick={handlerSirsoNewsRight}
            />
          </div>
        </div>

        {/* sirso top News------------------------- */}
        <Link
          to={`/singlenews/${sirsoNews[0]?.article_id}`}
          className="relative cursor-pointer duration-500 hover:gap-5 rounded-md card hover:z-20"
        >
          <img
            src={sirsoNews[0]?.images[0].file_path}
            alt=""
            className="rounded-sm object-cover"
          />

          <div className="single_cardoverlay">
            <div className="text-white p-5 absolute bottom-0">
              <div className="flex gap-2 items-baseline">
                <FaCalendarAlt className="text-sm" />
                {sirsoNews[0]?.publication_date && (
                  <p className="text-sm ">
                    {format(
                      new Date(sirsoNews[0]?.publication_date),
                      'MMM-dd-yyyy'
                    )}
                  </p>
                )}
              </div>
              {/* <p className="text-sm pb-1">{sirsoNews[0]?.publication_date}</p> */}
              <h2 className="">{sirsoNews[0]?.title}</h2>
            </div>
          </div>
        </Link>
        <hr className="my-5 outline-1" />

        {/* sirso all News------------------------- */}
        <div className="grid grid-cols-2 gap-5">
          {sirsoNews?.map((news, i) => (
            <Link
              to={`/singlenews/${news?.article_id}`}
              key={i}
              className="shadow rounded-sm"
            >
              <img
                src={news?.images[0]?.file_path}
                alt=""
                className="rounded-sm object-cover w-full"
              />
              <div className="mt-2 p-2 md:p-2">
                <div className="flex gap-2 text-[#908C8C]">
                  <FaCalendarAlt className="text-sm " />
                  {news?.publication_date && (
                    <p className="text-sm">
                      {format(new Date(news?.publication_date), 'MMM-dd-yyyy')}
                    </p>
                  )}
                </div>
                <h2 className="text-sm text-justify">
                  {news?.title.length > 45
                    ? news?.title?.slice(0, 45) + '...'
                    : news?.title}
                </h2>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* Alocito News ----------------- */}
      <div>
        <hr className="border-b border-red mt-10 lg:mt-0" />
        <div className="flex justify-between">
          <h2 className="text-red text-xl font-bold py-3">আলোচিত সংবাদ</h2>

          <div className="flex gap-1 items-center text-[#B8B8B8]">
            <FaLongArrowAltLeft
              className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
              onClick={handleAlocitoLeft}
            />
            /
            <FaLongArrowAltRight
              className="font-bold text-xl cursor-pointer hover:text-sky duration-500"
              onClick={handleAlocitoRight}
            />
          </div>
        </div>

        {/* Alocito Top News------------------------ */}
        <Link
          to={`/singlenews/${alocitoNews[0]?.article_id}`}
          className="relative cursor-pointer duration-500 hover:gap-5 rounded-md card hover:z-20"
        >
          <img
            src={alocitoNews[0]?.images[0].file_path}
            alt=""
            className="rounded-sm object-cover"
          />
          <div className="single_cardoverlay ">
            <div className="text-white p-5 absolute bottom-0">
              <div className="flex gap-2 items-baseline">
                <FaCalendarAlt className="text-sm" />
                {alocitoNews[0]?.publication_date && (
                  <p className="text-sm ">
                    {format(
                      new Date(alocitoNews[0]?.publication_date),
                      'MMM-dd-yyyy'
                    )}
                  </p>
                )}
              </div>
              {/* <p className="text-xs md:text-sm pb-1">
                {alocitoNews[0]?.publication_date}
              </p> */}
              <h2 className="">{alocitoNews[0]?.title}</h2>
            </div>
          </div>
        </Link>
        <hr className="my-5 outline-1" />

        {/* <div className='flex flex-col'> */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-0 md:gap-5 lg:gap-0 ">
          {alocitoNews?.map((news, i) => (
            <Link to={`/singlenews/${news?.article_id}`} key={i} className="">
              <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-0 lg:gap-5 items-center md:items-start lg:items-center rounded-sm">
                <img
                  src={news?.images[0]?.file_path}
                  alt=""
                  className="rounded-sm w-full md:col-span-2 lg:col-span-1 object-cover"
                />

                <div className="md:mt-3 lg:mt-0 p-0 md:p-2 lg:p-0 col-span-2">
                  <div className="flex gap-2 mb-1 text-[#908C8C]">
                    <FaCalendarAlt className="" />
                    {news?.publication_date && (
                      <p className="text-sm">
                        {format(
                          new Date(news?.publication_date),
                          'MMM-dd-yyyy'
                        )}
                      </p>
                    )}
                  </div>

                  <h2>
                    {news?.title.length > 60
                      ? news?.title?.slice(0, 60) + '...'
                      : news?.title}
                  </h2>
                </div>
              </div>
              {i + 1 === alocitoNews?.length ? (
                <></>
              ) : (
                <hr className="col-span-3 my-3 lg:my-4 md:hidden bolck border-[#E2E2E2] lg:block" />
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AlocitoNews;
