import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { FaLongArrowAltRight, FaLongArrowAltLeft, FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../../src/Assets/css/postCarousel.css";

const PostCarousel = ({ categoryNews }) => {
  const swiperRef = useRef(null);

  const renderPost = (news, index) => {
    // const { news?.images[ 0 ].file_path, date, title } = news;



    return (
      <SwiperSlide key={index}>
        <Link to={`/singlenews/${news?.article_id}`} className="carousel-link">
          <div className="carousel-card flex flex-col gap-3 my-2 shadow rounded-sm">
            <img src={news?.images[0]?.file_path} alt="Post" className="carousel-image object-cover rounded-sm" />
            <div className="carousel-card-info p-2">
              <div className="flex items-center space-x-2 text-[#B8B8B8] text-sm">
                <div><FaCalendarAlt /></div>
                <div className="text-gray-900">{news?.publication_date}</div>

              </div>

              <div className="text-black py-2 h-24 text-justify">{news?.title.length > 70
                ? news?.title.slice(0, 70) + ['..']
                : news?.title}</div>
            </div>
          </div>
        </Link>
      </SwiperSlide>
    );
  };

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };


  return (
    <div className="postCarousel">
      <div className="carousel-controls mb-2">
        <div className="carousel-arrow prev" onClick={handlePrevClick}>
          <FaLongArrowAltLeft className="carousel-arrow-icon text-[#B8B8B8] hover:text-red" />
        </div>
        <div className="text-[#B8B8B8] outline-none">/</div>
        <div className="carousel-arrow next" onClick={handleNextClick}>
          <FaLongArrowAltRight className="carousel-arrow-icon text-[#B8B8B8] hover:text-red" />
        </div>
      </div>
      <div className="swiper-container">
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          spaceBetween={30}
          navigation={false}
          breakpoints={{
            // when window width is >= 768px (sm)
            768: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            // when window width is >= 1024px (md)
            1024: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            // when window width is >= 1280px (lg)
            1280: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            // when window width is >= 1536px (xl)
            1536: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          }}
        >
          {categoryNews.map(renderPost)}
        </Swiper>
      </div>
    </div >
  );
};

export default PostCarousel;
