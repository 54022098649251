import React, { useState } from 'react';
import donate from '../../../Assets/Logo/donate.png';

const Donate = () => {
  const [amount, setAmount] = useState();

  const amounts = [50, 100, 250, 500, 1000, 2000];

  const handleDonateAmount = (amount) => {
    setAmount(amount);
  };

  const handleDonate = (e) => {
    e.preventDefault();
    console.log(e.target.donate.value);
  };

  return (
    <div className="mt-16 shadow-lg flex flex-col justify-center items-center relative border border-[#908c8c49]">
      <img src={donate} alt="" className="w-1/6 absolute -top-10" />

      <div className="bg-white w-full px-12 pt-12 pb-3 text-center rounded-b-3xl">
        <h2 className="text-xl font-semibold mb-5">Make a Secure Donation</h2>
        <div className="flex justify-center items-center text-base font-semibold">
          <button className="focus:text-sky focus:underline text-[#B8B8B8] hover:text-sky hover:underline decoration-2 underline-offset-8 duration-500 cursor-pointer transition-all">
            ONE TIME
          </button>
          {/* <button className="focus:text-sky focus:underline text-[#B8B8B8] hover:text-sky hover:underline decoration-2 underline-offset-8 duration-500 cursor-pointer transition-all">
            MONTHLY
          </button> */}
        </div>
      </div>
      <div className="w-4/5 mx-auto ">
        <div className="grid grid-cols-3 gap-5 my-10">
          {amounts?.map((amount, i) => (
            <div
              key={i}
              className="bg-white px-4 text-center py-2 rounded-md text-[#B8B8B8]"
            >
              <button
                onClick={() => handleDonateAmount(amount)}
                className="text-lg font-semibold hover:text-black duration-500"
              >
                ${amount}
              </button>
            </div>
          ))}
          <form onSubmit={(e) => handleDonate(e)} className="col-span-3">
            <input
              type="number"
              name="donate"
              placeholder="USD$"
              defaultValue={amount && amount}
              className="px-3 py-2 rounded-md w-full"
            />
            <button
              type="submit"
              className="bg-sky w-full my-10 text-white text-lg py-2 rounded-lg"
            >
              Donate $
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Donate;
