import React from 'react';

const YoutubeThumbnail = ({ videoUrl }) => {

    const getYouTubeThumbnail = (videoUrl) => {
        const videoId = videoUrl.split('/').pop();

        const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
        return thumbnailUrl;
    };

    const thumbnailUrl = getYouTubeThumbnail(videoUrl);
    return (
        <div >
            <img src={thumbnailUrl} alt="Video Thumbnail" className='w-28 h-16 md:w-full md:h-full object-cover ' />
        </div>
    );
};

export default YoutubeThumbnail;