import React from 'react';
import logo from '../../Assets/Logo/logo.png';
import add from '../../Assets/images/Rectangle 4.png';
import { Link } from 'react-router-dom';

const LogoAds = () => {
  return (
    <div className="w-11/12 lg:w-4/5 mx-auto">
      <div className="lg:flex lg:justify-between gap-5 py-1 lg:py-3">
        <Link to="/" className="hidden lg:block lg:w-2/5">
          <img src={logo} alt="" className="object-cover w-4/5" />
        </Link>

        <div className="w-full lg:w-3/5">
          <img src={add} alt="" className="object-cover" />
          {/* <img src={ads} alt="" className="object-cover" /> */}
        </div>
      </div>
    </div>
  );
};

export default LogoAds;
