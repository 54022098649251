import React from 'react';
import Rajniy from '../Rajnity/Rajniy';
import LayeredHome from '../../LayeredHome/LayeredHome';
import NagorikBinodo from '../NagorikBinodon/NagorikBinodo';

const Home = () => {
    return (
        <div className='my-5 w-11/12 lg:w-4/5 mx-auto'>
            <Rajniy></Rajniy>
            {/* <AlocitoNews></AlocitoNews> */ }
            <LayeredHome></LayeredHome>
            <div className='hidden lg:block'>
                <NagorikBinodo></NagorikBinodo>
            </div>
        </div>
    );
};

export default Home;