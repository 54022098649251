import React, { useState } from 'react';
// import userImg from '../../Assets/images/user-img.png';
import { FaUserAlt } from 'react-icons/fa';
import { useEffect } from 'react';
import axios from 'axios';

const PostComment = ({ id }) => {
  const [comment, setComment] = useState('');
  const [commentsList, setCommentsList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  // Get Article Comments---------------------------------
  useEffect(() => {
    axios(`https://app.zufaa.tech/nagorik-tv/article-comment/${id}`)
      .then((res) => {
        setCommentsList(res?.data);
        // setCommentsList(res?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [id, refresh]);

  // const handleNameChange = (event) => {
  //   setName(event.target.value);
  // };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  // Post Comments-------------------------
  const submitComment = (event) => {
    event.preventDefault();

    const postComment = {
      comment_content: comment,
      article_id: id,
    };
    axios
      .post('https://app.zufaa.tech/nagorik-tv/article-comment', postComment)
      .then((res) => {
        // console.log(res);
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.log(error);
      });

    // ---------------------------------------------------------------------
    // Joy Vai code------------------------------------------------------

    // const newComment = {
    //   name,
    //   comment,
    //   article_id: id,
    //   time: new Date().toLocaleString(),
    // };
    // setCommentsList((prevComments) => [...prevComments, newComment]);
    // setName('');
    // setComment('');
  };

  return (
    <div className="flex flex-col items-start w-11/12">
      <div className="w-full">
        <div className="mb-4">
          <h3 className="text-lg text-black font-bold">Comments:</h3>
          {commentsList.map((comment, index) => (
            <div key={index} className="flex gap-5 p-4 my-2 ">
              <div className="user-img shrink-0 bg-[#cccccc] p-4 rounded-sm">
                <FaUserAlt className="text-[#a8a7a7] text-5xl" />
              </div>

              <div className="user-comments bg-commentsbg w-full p-5 rounded-sm">
                <p className="text-[#5F5C5C] text-justify">
                  {comment?.comment_content}
                </p>
                <p className="text-[#5F5C5C] text-sm"> {comment?.created_at}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="mb-4 flex flex-col ">
          <h2 className="text-lg font-bold">Leave a Comment</h2>
          <form onSubmit={submitComment} className="flex flex-col ">
            {/* <input
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder="Your Name"
              className="border border-[#B6B5B5] p-2 mb-2 rounded-md "
            /> */}
            <textarea
              defaultValue={comment}
              onChange={handleCommentChange}
              placeholder="Write your comment..."
              className="border border-[#B6B5B5] p-2 mb-2 rounded-md"
            ></textarea>

            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-red hover:bg-sky text-white py-2 px-5 rounded inline-block transition-all duration-300"
              >
                Post a Comment
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PostComment;
