import React, { useEffect, useRef } from 'react';
import { FacebookProvider, Page } from 'react-facebook';

import './FacebookPost.css';

const FacebookPost = () => {
  const fbContainerRef = useRef(null);

  useEffect(() => {
    const loadFacebookSDK = () => {
      // Load the Facebook SDK asynchronously
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '1280632108785897',
          xfbml: true,
          version: 'v13.0',
        });
        const updateWidthAndHeight = () => {
          if (fbContainerRef.current) {
            const containerWidth = fbContainerRef.current.clientWidth;
            const containerHeight = 700; // Adjust the height as needed
            const fbPageDiv = document.querySelector('.fb-page');
            if (fbPageDiv) {
              fbPageDiv.setAttribute('data-width', containerWidth);
              fbPageDiv.style.height = `${containerHeight}px`; // Set the height using CSS
              window.FB.XFBML.parse(); // Re-parse the Facebook plugin to update the height
            }
          }
        };
        updateWidthAndHeight();
        window.addEventListener('resize', updateWidthAndHeight);
        return () => {
          window.removeEventListener('resize', updateWidthAndHeight);
        };
      };
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src =
          'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0&appId=1280632108785897&autoLogAppEvents=1';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    };
    loadFacebookSDK();
  }, []);

  return (
    <div>
      <hr className="border-b border-red mt-10" />
      <h2 className="text-red text-xl font-bold py-2">ফেসবুক এ নাগরিক টিভি</h2>
      <div className="rounded-b-sm" ref={fbContainerRef}>
        <div className="fb-container mx-auto w-full max-w-md">
          <div style={{ height: '600px' }}>
            <FacebookProvider appId="1280632108785897">
              <Page
                href="https://www.facebook.com/NagorikTV/"
                tabs="timeline"
                width=""
                height="600"
                smallHeader={true}
                adaptContainerWidth={true}
                hideCover={false}
                showFacepile={true}
              />
            </FacebookProvider>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FacebookPost;
