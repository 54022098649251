import React from 'react';
import AlocitoNews from '../Home/AlocitoNews/AlocitoNews';
import DiscountAdd from '../../Shared/DiscountAdd/DiscountAdd';
import NagorikViral from '../Home/NagorikViral/NagorikViral';
import SocialMediaFollowes from '../Home/SocialMediaFollowes/SocialMediaFollowes';
import FacebookPost from '../Home/FacebookPost/FacebookPost';
import OnlineVote from '../Home/OnlineVote/OnlineVote';
import RecentNews from '../Home/RecentNews/RecentNews';
import EditorLikes from '../Home/EditorLikes/EditorLikes';
import Ads from '../../Shared/Ads/Ads';
import Donate from '../Home/Donate/Donate';
import NagorikBinodo from '../Home/NagorikBinodon/NagorikBinodo';
import VotingComponent from '../../Components/voting/votingSystem';

const LayeredHome = () => {
  return (
    <div className="lg:grid grid-cols-3 gap-5 mt-14">
      {/* Home Left side design---------------------- */}
      <div className="col-span-2">
        <AlocitoNews></AlocitoNews>
        <NagorikViral></NagorikViral>
        <RecentNews></RecentNews>
        <EditorLikes></EditorLikes>
        <Ads></Ads>
        <div className="block lg:hidden">
          <NagorikBinodo></NagorikBinodo>
        </div>
      </div>

      {/* Home Right side ---------------------- */}
      <div>
        <DiscountAdd></DiscountAdd>
        <SocialMediaFollowes></SocialMediaFollowes>
        <FacebookPost></FacebookPost>
        <OnlineVote></OnlineVote>
        <VotingComponent></VotingComponent>
        <Donate></Donate>
      </div>
    </div>
  );
};

export default LayeredHome;
