import React, { useEffect, useState } from 'react';
import logo from '../../Assets/Logo/category.png';
import { Link, useParams } from 'react-router-dom';
import { FaCalendarAlt, FaChevronCircleRight, FaHome } from 'react-icons/fa';
import axios from 'axios';
import { format } from 'date-fns';

const JustCategory = () => {
  const [newses, setNews] = useState([]);
  const category = useParams();

  const url = `https://app.zufaa.tech/nagorik-tv/category-article/${category?.cId}/1/10`;

  useEffect(() => {
    axios(url)
      .then((res) => {
        window.scrollTo(0, 0);
        // console.log(res);
        setNews(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [url]);

  return (
    <div className="w-11/12 lg:w-4/5 mx-auto my-2">
      <div className="flex justify-between items-baseline">
        <h2 className="text-lg md:text-2xl lg:text-3xl font-bold mb-3 ">
          {category?.name}
        </h2>
        <div className="flex justify-end w-1/2">
          <img src={logo} alt="" className="w-1/4" />
        </div>
      </div>
      <hr className="mb-3 -mt-3 md:-mt-2 border md:border-b-2 lg:border-b-4" />
      <div className="flex gap-1 text-[#808080] md:text-lg items-center">
        <FaHome /> /
        <Link to="/" className="hover:text-sky duration-500">
          হোম
        </Link>{' '}
        /<h2 className="text-gray">{category?.name}</h2>
      </div>
      <hr className="my-3 border-b-1 border-[#808080]" />

      {newses?.length ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-8">
          {newses?.map((news, i) => (
            <div key={i} className="bg-white rounded-sm">
              <Link to={`/singlenews/${news?.article_id}`}>
                <img
                  src={news?.images[0]?.file_path}
                  alt=""
                  className="rounded-t-sm h-64 w-full object-cover"
                />
              </Link>
              <div className="flex flex-col gap-3 p-5">
                <div className="flex gap-5">
                  <h2>
                    Posted by <span className="text-[#3B5998]">NagorikTV</span>
                  </h2>
                  <div className="flex gap-2 mb-1 items-baseline text-[#3B5998]">
                    <FaCalendarAlt className="" />
                    {news?.publication_date && (
                      <p className=" ">
                        {format(
                          new Date(news?.publication_date),
                          'MMM-dd-yyyy'
                        )}
                      </p>
                    )}
                  </div>
                  {/* <p className="text-[#3B5998]">{news?.publication_date}</p> */}
                </div>

                <Link
                  to={`/singlenews/${news?.article_id}`}
                  className="text-xl hover:text-[#3B5998] duration-500 font-semibold"
                >
                  {news?.title}
                </Link>
                <p>
                  {news?.content?.length > 200
                    ? news?.content?.slice(0, 200) + '....'
                    : news?.content}
                </p>

                <Link
                  to={`/singlenews/${news?.article_id}`}
                  className="text-lg text-[#3B5998] font-semibold  flex gap-2 justify-end items-center"
                >
                  <span> Read More</span>
                  <FaChevronCircleRight className="text-xl" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <h2 className="text-xl font-bold text-success text-center my-24">
          No News is Uploaded Yet..........
        </h2>
      )}
    </div>
  );
};

export default JustCategory;
