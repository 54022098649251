import React from 'react';
import ShareButtons from '../../../Shared/ShareButtons/ShareButtons';

const SinglePoll = ({
  poll,
  selectedOption,
  handleRadioChange,
  getIPAddress,
  ipAddress,
  handleVote,
}) => {
  // /voting/:id
  // console.log(useLocation());
  let PollUrl = `${window.location.origin}/voting/${poll?.id}`;
  //   console.log(PollUrl);

  const shareUrlWithData = `${poll.question}\nOptions:\n1. ${poll.option_1}: ${poll.option_1_percentage}%\n2. ${poll.option_2}: ${poll.option_2_percentage}%\n3. ${poll.option_3}: ${poll.option_3_percentage}%\n${PollUrl}`;
  //   console.log(shareUrlWithData);

  return (
    <div className="bg-white shadow rounded-sm mt-5">
      <img
        src={poll?.image}
        alt="voting"
        className="w-full h-64 object-cover rounded-sm"
      />

      <form className="p-5">
        <h2 className="text-base font-semibold my-4">{poll?.question}</h2>
        {/* { Object.keys( voteLabels ).map( ( vote ) => ( */}
        {/* Option_1 Poll--------------------- */}
        <div className="relative w-full bg-[#E7E5E5] h-9 rounded overflow-hidden mb-4 p-1">
          <div
            style={{ width: `${poll?.option_1_percentage}%` }}
            className={`absolute inset-0 transition-all duration-500 ease-in-out bg-sky `}
          />
          <div className="absolute inset-0 flex items-center justify-between">
            <label className="flex items-center gap-2 ml-2">
              <input
                type="radio"
                value={poll?.option_1}
                checked={selectedOption === `${poll?.option_1}`}
                onChange={handleRadioChange}
                className="w-5 h-5"
                disabled={getIPAddress === ipAddress}
              />
              <span className="text-black">{poll?.option_1}</span>
            </label>
            <span className="text-black mr-2">{`${poll?.option_1_percentage}%`}</span>
          </div>
        </div>
        {/* Option_2 Poll--------------------- */}
        <div className="relative w-full bg-[#E7E5E5] h-9 rounded overflow-hidden mb-4 p-1">
          <div
            style={{ width: `${poll?.option_2_percentage}%` }}
            className={`absolute inset-0 transition-all duration-500 ease-in-out bg-sky `}
          />
          <div className="absolute inset-0 flex items-center justify-between">
            <label className="flex items-center gap-2 ml-2">
              <input
                type="radio"
                value={poll?.option_2}
                checked={selectedOption === `${poll?.option_2}`}
                onChange={handleRadioChange}
                className="w-5 h-5"
                disabled={getIPAddress === ipAddress}
              />
              <span className="text-black">{poll?.option_2}</span>
            </label>
            <span className="text-black mr-2">{`${poll?.option_2_percentage}%`}</span>
          </div>
        </div>
        {/* Option_3 Poll--------------------- */}
        <div className="relative w-full bg-[#E7E5E5] h-9 rounded overflow-hidden mb-4 p-1">
          <div
            style={{ width: `${poll?.option_3_percentage}%` }}
            className={`absolute inset-0 transition-all duration-500 ease-in-out bg-sky `}
          />
          <div className="absolute inset-0 flex items-center justify-between">
            <label className="flex items-center gap-2 ml-2">
              <input
                type="radio"
                value={poll?.option_3}
                checked={selectedOption === `${poll?.option_3}`}
                onChange={handleRadioChange}
                className="w-5 h-5"
                disabled={getIPAddress === ipAddress}
              />
              <span className="text-black">{poll?.option_3}</span>
            </label>
            <span className="text-black mr-2">{`${poll?.option_3_percentage}%`}</span>
          </div>
        </div>
        <div className="flex justify-between items-center  gap-4 mt-4">
          <button
            onClick={(e) => handleVote([e, poll])}
            className="px-4 py-2 rounded bg-sky text-white hover:bg-blue-600 disabled:bg-gray disabled:opacity-50 disabled:pointer-events-none"
            disabled={getIPAddress === ipAddress}
            // disabled={ userVote === null }
          >
            ভোট দিন
          </button>
          {/* <SocialShare copyLink={copyLink} votePoll={poll} /> */}

          <ShareButtons
            shareUrl={PollUrl}
            shareUrlWithData={shareUrlWithData}
          />
        </div>
      </form>
    </div>
  );
};

export default SinglePoll;
