import React from 'react';
import notPage from '../../Assets/images/404 page.png'

const PageNotFound = () => {
    return (
        <div className='flex justify-center items-center'>
            <img src={ notPage } alt="" />
        </div>
    );
};

export default PageNotFound;