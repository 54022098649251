import React, { useEffect, useState } from 'react';
import { FaChevronCircleRight, FaHome } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import img from '../../Assets/images/bidenkaku.png';
import logo from '../../Assets/Logo/category.png'
import axios from 'axios';

const Category = () => {
    const [newses, setNewes] = useState([]);
    const [category, setCategory] = useState({})
    const ids = useParams();


    const url = `https://app.zufaa.tech/nagorik-tv/articleapi/api/articlewithsub.php?category_id=${ids?.cId}&subcategory_id=${ids?.subId}&page=1&limit=10`
    // Get Categrory Article-----------------------------
    useEffect(() => {
        axios(url)
            .then(res => {
                // console.log( res.data );
                setNewes(res?.data);
                window.scrollTo(0, 0);
            })
            .catch(error => {
                console.log(error);
            })
    }, [url])

    // Get Category-----------------------
    useEffect(() => {
        axios(`https://app.zufaa.tech/nagorik-tv/categories/${ids?.cId}`)
            .then(res => {
                // console.log( res.data );
                setCategory(res?.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, [ids?.cId])



    return (
        <div className='w-11/12 lg:w-4/5 mx-auto my-2'>
            <div className='flex justify-between items-baseline'>
                <h2 className='text-lg md:text-2xl lg:text-3xl font-bold mb-3 '>{category?.name}</h2>
                <div className='flex justify-end w-1/2'>
                    <img src={logo} alt="" className='w-1/4' />
                </div>
            </div>
            <hr className='mb-3 -mt-3 md:-mt-2 border md:border-b-2 lg:border-b-4' />
            <div className='flex gap-1 text-[#808080] md:text-lg items-center'>
                <FaHome /> /
                <Link to='/' className='hover:text-sky duration-500'>হোম</Link> /
                <Link to={`/newscategory/${category?.name}/${category?.category_id}`} className='hover:text-sky duration-500'>{category?.name}</Link>
            </div>
            <hr className='my-3 border-b-1 border-[#808080]' />

            {
                newses?.length ?

                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-8'>
                        {
                            newses?.map((news, i) =>
                                <div key={i} className='bg-white rounded-sm'>
                                    <img src={news?.images[0]?.file_path} alt="" className='rounded-sm h-56 w-full object-cover' />
                                    <div className='flex flex-col gap-3 p-5'>
                                        <div className='flex gap-5'>
                                            <h2>Posted by <span className='text-[#3B5998]'>NagorikTV</span></h2>
                                            <p className='text-[#3B5998]'>{news?.publication_date}</p>
                                        </div>
                                        <h2 className='text-xl font-semibold'>{news?.title?.length > 75 ? news?.title.slice(0, 75) + ('...') : news?.title}</h2>
                                        <p className='text-justify'>{news?.content?.length > 190 ? news?.content?.slice(0, 190) + ('....') : news?.content}</p>


                                        <Link to={`/singlenews/${news?.article_id}`} className='text-lg text-[#3B5998] font-semibold mt-5 flex gap-2 justify-end items-center'>
                                            <span> Read More</span>
                                            <FaChevronCircleRight className='text-xl' />
                                        </Link>

                                    </div>
                                </div>
                            )
                        }

                    </div>
                    :
                    <h2 className='text-xl font-bold text-success text-center my-24'>No News is Uploaded Yet..........</h2>
            }

        </div>
    );
};

export default Category;